import { config } from 'react-spring'

const staggeredTransitionConfig = {
    key: (item: any) => item,
    from: {opacity: 0, y: -10},
    enter:
        ({idx}: any) =>
            (next: any) =>
                next({opacity: 1, y: 0, delay: idx * 150}),
    leave:
        ({idx}: any) =>
            (next: any) =>
                next({opacity: 0, y: -10, delay: idx * 150}),
    config: config.gentle,    
}

const flagTransitionConfig = {
    key: (item: any) => item,
    from: {opacity: 0, y: -10},
    enter:
        ({childIdx}: any) =>
            (next: any) =>
                next({opacity: 1, y: 0, delay: childIdx * 150}),
    leave:
        ({childIdx}: any) =>
            (next: any) =>
                next({opacity: 0, y: -10, delay: childIdx * 150}),
    config: config.gentle,
}

const fadeInSpringConfig = {
    to: {opacity: 1},
    from: {opacity: 0},
}


const fadeInDownTransitionConfig = {
    from: {opacity: 0, y: -10},
    enter: {opacity: 1, y: 0},
    leave: {opacity: 0, y: -10},
}

export {
    flagTransitionConfig,
    staggeredTransitionConfig,
    fadeInSpringConfig,
    fadeInDownTransitionConfig,
}
