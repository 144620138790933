import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { checkmarkCircle, refreshCircle } from 'ionicons/icons';
import {ExtendedAssetsMetaItem, useSettings} from './useSettings';
import { useTranslation } from 'react-i18next';
import { parseConfigFileName } from '../../utils/formatter.utils';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { useCallback, useEffect, useState } from 'react';
import { useDownloadStatsStore } from '../../stores/downloadStats/useDownloadStats.store';
import { useDownloadManager } from '../../hooks/download-manager/useDownloadManager';
import { BaseGridContainer } from '../base-grid-container';
import { animated, useTransition } from 'react-spring';
import toast from 'react-hot-toast';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { FILESYSTEM_PREFIX } from '../../constants/files-directories';
import { Dialog } from '@capacitor/dialog';
//import * as SentryReact from '@sentry/react';
import prettyBytes from 'pretty-bytes';
import { AssetMetaItem } from '../../stores/coreDataV2/coreData-v2-types';

export const supportedFiles = ['png', 'jpg', 'jpeg', 'mp4', 'mov', 'm3u8', 'ts'];

export const SettingsDataPage = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const transitions = useTransition(isLoading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [missingAssets, setMissingAssets] = useState<{ files: AssetMetaItem[]; totalBytesToDownload: number }>({
    files: [],
    totalBytesToDownload: 0,
  });

  const {
    currentState,
    updateCurrentState,
    updateBytesRemaining,
    updateTotalBytes,
    updateNumberOfAssetsTotal,
    updateNumberOfAssetsDownloaded,
    increaseNumberOfAssetsDownloaded,
    ...downloadStatsStore
  } = useDownloadStatsStore();
  const userDefaultsStore = useUserDefaultsStore();

  const { checkForUpdates, gatherUpdateInfo } = useSettings();
  const { downloadAssets, downloadInfo } = useDownloadManager();

  const [updateInfo, setUpdateInfo] = useState<{ assetsNotDownloaded: AssetMetaItem[]; totalBytes: number }>();

  const onCheckForUpdates = async () => {
    setLoading(true);
    updateCurrentState('IDLE');
    let missingAssetsInfo = null;

    try {
      await checkAllFiles();
    } catch (e: any) {
      missingAssetsInfo = e;
    }

    const isUpdateAvailable = await checkForUpdates();

    if (isUpdateAvailable) {
      const updateInfoData = await gatherUpdateInfo();
      setUpdateInfo(updateInfoData);
      updateTotalBytes(updateInfoData.totalBytes);
      updateBytesRemaining(updateInfoData.totalBytes);
      updateNumberOfAssetsTotal(updateInfoData.assetsNotDownloaded?.length);
      updateNumberOfAssetsDownloaded(0);
    } else if (missingAssetsInfo?.missingAssets?.length) {
      updateCurrentState('MISSING_ASSETS');
      toast.error(`${missingAssetsInfo?.missingAssets?.length} assets are missing
          (${prettyBytes(missingAssetsInfo?.totalBytesToDownload || 0)}).`);
    } else {
      toast.success(t('updateAlert.noupdateAvailable__message'));
    }
    setLoading(false);
  };

  const onDownloadAllUpdates = useCallback(async () => {
    if (!updateInfo) return;

    updateCurrentState('DOWNLOADING');
    updateNumberOfAssetsTotal(updateInfo.assetsNotDownloaded?.length || 0);
    updateTotalBytes(updateInfo.totalBytes || 0);
    updateBytesRemaining(updateInfo.totalBytes || 0);
    
    try {
      await downloadAssets(updateInfo.assetsNotDownloaded);
    } catch (error) {
      console.log(error);
    }

    toast.success(t('settings.dataManagement.downloadUpdateDone__message'));
  }, [updateCurrentState, updateInfo]);

  const checkAllFiles = useCallback(async () => {
    const availableData = useUserDefaultsStore.getState().availableData;

    const paths = Object.values(availableData).map(({ local }) => local);
    const allAssets = [];

    for (const path of paths) {
      try {
        const { data } = await Filesystem.readFile({
          directory: Directory.Documents,
          path: path as string,
          encoding: Encoding.UTF8,
        });
        allAssets.push(...JSON.parse(data as string)?.meta?.assets?.items);
      } catch (e) {
        console.log(e);
      }
    }
    const uniqueAssets = allAssets.flat().reduce((acc: AssetMetaItem[], current) => {
      const x = acc.find((item) => item.url === current.url);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);


    const extendedUniqueAssets: ExtendedAssetsMetaItem[] = [];
    uniqueAssets.forEach(({ url, sizeKbs }: any) => {
      const fullUrlObj = new URL(url);
      const path = fullUrlObj.pathname.split('/').slice(0, -1).join('/');
      const fileName = fullUrlObj.pathname.substring(fullUrlObj.pathname.lastIndexOf('/') + 1);
      extendedUniqueAssets.push({ url, path, fileName, sizeKbs });
    });

    const missingAssets: AssetMetaItem[] = [];
    const downloadedAssets: AssetMetaItem[] = [];


    // check for missing assets
    const assetsDirContents = await Filesystem.readdir({
      directory: Directory.Documents,
      path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment}/assets/`,
    });

    for (const file of extendedUniqueAssets) {
      try {
          file.url = file.url.replace(' ', '%20')
          if (!assetsDirContents.files.some(fileInfo => fileInfo.name === file.fileName.replace(' ', '%20'))) {
            missingAssets.push(file);
          } else {
            downloadedAssets.push(file);
          }
      } catch (e) {
        missingAssets.push(file);
      }
    }

    // check for broken assets
    const checkedAssets: { broken: string[]; fine: string[] } = { broken: [], fine: [] };
    
    for (const asset of downloadedAssets) {
      try {
        const fullUrlObj = new URL(asset.url);
        const path = fullUrlObj.pathname;
        
        const fileStats = await Filesystem.stat({
          directory: Directory.Documents,
          path,
        });

        if (fileStats.size < 1000) {
          checkedAssets.broken.push(path);
        } else {
          checkedAssets.fine.push(path);
        }
      } catch (e) {
        console.error(`Error while reading asset: ${e}`);
      }
    }

    if (checkedAssets.broken.length) {
      try {
        for (const brokenAssetPath of checkedAssets.broken) {
          const readResponse = await Filesystem.readFile({
            directory: Directory.Documents,
            path: brokenAssetPath,
            encoding: Encoding.UTF8,
          });

          if (
            supportedFiles.some((supportedFileType) => brokenAssetPath.endsWith(supportedFileType)) &&
            (readResponse.data as string).includes('<HTML>')
          ) {
            // console.log(`Deleting broken asset: ${brokenAssetPath}`);
            //SentryReact.captureMessage(`Deleting broken asset: ${brokenAssetPath}`);
            await Filesystem.deleteFile({
              directory: Directory.Documents,
              path: brokenAssetPath,
            });
            toast.success(`Deleted broken asset: ${brokenAssetPath}`);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    const totalBytesToDownload = missingAssets.map(({ sizeKbs }) => sizeKbs || 0).reduce((acc, curr) => acc + curr, 0) * 1024;

    updateTotalBytes(totalBytesToDownload);
    updateBytesRemaining(totalBytesToDownload);
    updateNumberOfAssetsTotal(uniqueAssets?.length);
    updateNumberOfAssetsDownloaded(uniqueAssets?.length - missingAssets?.length);

    setMissingAssets({ files: missingAssets, totalBytesToDownload });

    if (missingAssets?.length) {
      return Promise.reject({
        uniqueAssets,
        missingAssets,
        totalBytesToDownload,
      });
    } else {
      return Promise.resolve(t('checkAssets.checkAssets__messageOk'));
    }
  }, []);

  const checkForMissingAssets = useCallback(async () => {
    updateCurrentState('IDLE');
    
    try {
      await toast.promise(
        checkAllFiles(),
        {
          loading: t('checkAssets.checkAssets__messageProgress'),
          success: (msg) => msg,
          error: ({ totalBytesToDownload, missingAssets }) => {
            updateCurrentState('MISSING_ASSETS');
            
            const size = prettyBytes(totalBytesToDownload || 0);
            
            return t('checkAssets.checkAssets__messageMissing', {
              missing: missingAssets?.length,
              size,
            });
          },
        },
        { duration: 5000 }
      );
    } catch (e) {
      console.log(e);
    }
  }, [useUserDefaultsStore.getState().availableData, t]);

  const deleteAllAssets = useCallback(async () => {
    const { value } = await Dialog.confirm({
      title: t('deleteAssets.deleteAssets_alert__header'),
      message: t('deleteAssets.deleteAssets_alert__message'),
      okButtonTitle: t('deleteAssets.deleteAssets_alert__buttonOk'),
      cancelButtonTitle: t('deleteAssets.deleteAssets_alert__buttonCancel'),
    });

    if (value) {
      await Filesystem.deleteFile({ directory: Directory.Documents, path: `${userDefaultsStore.environment}/assets/` });
      toast.success(t('deleteAssets.deleteAssets_alert__messageSuccess'));
    }
  }, []);

  const downloadMissingAssets = useCallback(async () => {
    updateCurrentState('DOWNLOADING');
    await downloadAssets(missingAssets?.files);
  }, [missingAssets?.files]);

  useEffect(() => {
    setTimeout(() => {
      onCheckForUpdates();
    }, 1000);
  }, []);

  return (
    <BaseGridContainer
      className="!z-10 h-full bg-bosch-light-gray-w75 p-6"
      headline={t('settings.dataManagement.content__headline')}
      mostInnerClassName="pt-4"
      headerSlotRight={
        <>
          <IonButton
            fill="clear"
            onClick={() => {
              updateCurrentState('IDLE');
              userDefaultsStore.setShowSettingsDialog(false);
            }}
            disabled={currentState === 'DOWNLOADING'}
          >
            {t('generic.close__button')}
          </IonButton>
          <IonButton fill="solid" onClick={() => onCheckForUpdates()} disabled={currentState === 'DOWNLOADING'}>
            {t('settings.dataManagement.checkForUpdates__label')}
          </IonButton>
        </>
      }
    >
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="pointer-events-none fixed inset-0 z-10 flex items-center justify-center bg-white/80 font-semibold text-bosch-dark-blue"
            >
              {t('settings.dataManagement.checkForUpdatesLoading__label')}
            </animated.div>
          )
      )}

      <div className="row-span-8 relative col-span-12 h-full">
        <section className="w-full">
          {currentState === 'DOWNLOADING' && (
            <div className="relative mt-12 flex flex-col justify-between rounded-lg bg-white pl-8 pt-8 pr-8 pb-12 text-left shadow-lg">
              <IonSpinner name="crescent" className="absolute right-6 top-6 h-12 w-12" color="primary" />
              <div className="mb-2 text-xl font-bold text-bosch-dark-blue">
                {t('settings.dataManagement.downloadingFiles__label', {
                  received: prettyBytes(downloadInfo.total.bytesReceived || 0),
                  total: prettyBytes(downloadInfo.total.bytesTotal || 0),
                })}
              </div>
              <div className="relative mt-4">
                <div className="absolute left-0 top-4">0%</div>
                <div className="flex h-2.5 w-full rounded-full bg-bosch-light-gray-w50">
                  <div
                    className="h-2.5 rounded-full bg-bosch-dark-blue transition-all"
                    style={{ width: `${Math.floor((downloadInfo.total.bytesReceived * 100) / downloadInfo.total.bytesTotal)}%` }}
                  />
                </div>
                <div className="absolute right-0 top-4">100%</div>
              </div>
            </div>
          )}

          {currentState !== 'DOWNLOADING' && (
            <div className="grid w-full grid-cols-2 gap-6">
              {Object.entries(userDefaultsStore.availableData)?.map(([language, item]: any) => {
                const { local, remote, updateAvailable } = item;
                return (
                  <div key={language} className="relative rounded-lg bg-white p-6 text-left shadow-lg">
                    <div className="w-full">
                      <div className="text-xl font-bold text-bosch-dark-blue">{t(`languages.${language}`)}</div>
                      <div className="mt-2 flex justify-between text-sm">
                        <div>
                          <div className="font-bold">{t('settings.dataManagement.installedVersion__label')}</div>
                          <div>{parseConfigFileName(local, t('settings.dataManagement.noVersionInstalled__label'))}</div>
                        </div>
                        <div>
                          <div className="font-bold">{t('settings.dataManagement.latestVersion__label')}</div>
                          <div className="italic">
                            {local === remote && t('settings.dataManagement.alreadyInstalled__label')}
                            {local !== remote && parseConfigFileName(remote)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="absolute -right-3 -top-3">
                      {updateAvailable ? (
                        <IonIcon icon={refreshCircle} size="large" className="text-bosch-dark-blue" />
                      ) : (
                        <IonIcon icon={checkmarkCircle} size="large" className="text-bosch-light-green" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </section>
      </div>

      {currentState !== 'DOWNLOADING' && (
        <section className="fixed left-0 right-0 bottom-0 flex grid grid-cols-12 items-center justify-end gap-4 bg-white pb-4">
          <div className="col-span-10 col-start-2 flex justify-end">
            <IonButton fill="clear" onClick={checkForMissingAssets}>
              {t('checkAssets.checkAssets__button')}
            </IonButton>
            <IonButton fill="clear" color="danger" onClick={deleteAllAssets}>
              {t('deleteAssets.deleteAssets__button')}
            </IonButton>
          </div>
        </section>
      )}

      {currentState === 'UPDATE_AVAILABLE' && (
        <div className="fixed left-0 right-0 bottom-0 flex flex-col bg-bosch-dark-blue p-8 text-white">
          <div className="flex w-full items-center justify-between">
            {
              <div className="flex flex-col justify-start">
                <h1 className="text-xl font-bold">{t('settings.dataManagement.updateAvailable__label')}</h1>
                <div className="mt-2 flex">
                  <div>
                    {t('settings.dataManagement.updateAvailableContent__label')} <span className="font-semibold">(~1 MB)</span>
                  </div>
                  {downloadStatsStore.numberOfAssetsTotal > 0 && downloadStatsStore.totalBytes > 0 && (
                    <div className="px-4">
                      {t('settings.dataManagement.updateAvailableAssets__label')}{' '}
                      <span className="font-semibold">
                        ({downloadStatsStore.numberOfAssetsTotal} {t('settings.dataManagement.updateAvailableAssetsFiles__label')} ~
                        {prettyBytes(downloadStatsStore.totalBytes || 0)})
                      </span>
                    </div>
                  )}
                  {(downloadStatsStore.numberOfAssetsTotal === 0 ||
                    downloadStatsStore.numberOfAssetsDownloaded === downloadStatsStore.numberOfAssetsTotal) && (
                    <div className="px-4">{t('settings.dataManagement.updateNotAvailableAssets__label')}</div>
                  )}
                </div>
              </div>
            }

            <IonButton fill="solid" color="light" className="font-bold" onClick={onDownloadAllUpdates}>
              {t('settings.dataManagement.downloadUpdate__label')}
            </IonButton>
          </div>
        </div>
      )}

      {currentState === 'MISSING_ASSETS' && (
        <div className="fixed left-0 right-0 bottom-0 flex flex-col bg-bosch-dark-blue p-8 text-white">
          <div className="flex w-full items-center justify-between">
            {
              <div className="flex flex-col justify-start">
                <h1 className="text-xl font-bold">{t('missingAssets.missingAssets__header')}</h1>
                <div className="mt-2 flex">
                  {missingAssets?.files?.length > 0 && (
                    <div className="pr-4">
                      {t('missingAssets.missingAssets__body')}{' '}
                      <span className="font-semibold">
                        ({missingAssets?.files?.length} {t('settings.dataManagement.updateAvailableAssetsFiles__label')} ~
                        {prettyBytes(missingAssets?.totalBytesToDownload || 0)})
                      </span>
                    </div>
                  )}
                </div>
              </div>
            }

            <IonButton fill="solid" color="light" className="font-bold" onClick={downloadMissingAssets}>
              {t('missingAssets.missingAssets__buttonDownload')}
            </IonButton>
          </div>
        </div>
      )}
    </BaseGridContainer>
  );
};
