import { isNative } from './platform';
import { Service } from '../stores/coreDataV2/coreData-v2-types'
import { ReactNode } from 'react'

const getAttributeOverviewText = (data: any): string => {
    return data?.attributes.overviewtext
}

const getSubgridOverviewData = (service: Service): Record<string, string | undefined> | undefined => {
    if (!service) {
        return;
    }

    const bgImageUrl = service?.backgrounds?.find(bg => bg.fileName.includes('_overview_'))?.url;

    return {
        headline: service.headline,
        description: service.description,
        backgroundImage: isNative ? bgImageUrl?.replace(`${process.env.REACT_APP_BASE_URL_DATA}/`, '') : bgImageUrl,
    }
}

const getSubgridContentData = (data: any, bgFromAssets = false): Record<string, string | ReactNode> => {    
    const bgImageUrl = data?.background?.url

    return {
        headline: data?.headline,
        description: data?.description,
        backgroundImage: isNative ? bgImageUrl?.replace(`${process.env.REACT_APP_BASE_URL_DATA}/`, '') : bgImageUrl,
    }
}

export {
    getAttributeOverviewText,

    getSubgridOverviewData,
    getSubgridContentData,
}
