import { Service } from '../../stores/coreDataV2/coreData-v2-types';
import { routes } from '../../utils/routes';

export const getLinkForNextLevel = (service: Service, currentRoute?: string) => {
  if (service?.services?.length === 0) {
    return routes.details__flags_and_modal;
  } else {
    if (currentRoute === routes.services__overview_nested) {
      return routes.services__overview_nested_level_three;
    } else {
        return routes.services__overview_nested;
    }
  }
};
