import { useCallback } from "react";
import axios from "axios";
import {Directory, Encoding, Filesystem} from "@capacitor/filesystem";
import {DEFAULT_LANGUAGE, INITIAL_FILES} from "../constants/defaults";
import { BUNDLE_PREFIX, FILESYSTEM_PREFIX } from "../constants/files-directories";
import { useUserDefaultsStore } from "../stores/userDefaults/useUserDefaults.store";
import { CoreDataV2 } from "../stores/coreDataV2/coreData-v2-types";

export const useInitialDataHook = () => {
  const userDefaultsStore = useUserDefaultsStore();

  const copyInitialData = useCallback(async (): Promise<CoreDataV2> => {
    console.info("Copying initial data");
    let defaultData: CoreDataV2;

    for (const [language, filePath] of Object.entries(INITIAL_FILES)) {
      const { data } = await axios.get<CoreDataV2>(`${BUNDLE_PREFIX}${filePath}`, {
        baseURL: "/"
      });

      const path = filePath.split('/').pop() as string;

      await Filesystem.writeFile({
        path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment || process.env.REACT_APP_ENVIRONMENT}/schemas/${path}`,
        directory: Directory.Documents,
        data: JSON.stringify(data),
        recursive: true,
        encoding: Encoding.UTF8
      });

      await Filesystem.stat({
        path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment || process.env.REACT_APP_ENVIRONMENT}/assets/`,
        directory: Directory.Documents
      }).catch(async () => {
        await Filesystem.mkdir({
          directory: Directory.Documents,
          path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment || process.env.REACT_APP_ENVIRONMENT}/assets/`,
        });
      })

      userDefaultsStore.setAvailableData({ language, local: `${userDefaultsStore.environment || process.env.REACT_APP_ENVIRONMENT}/schemas/${filePath}` });
      userDefaultsStore.addLanguage(language);

      if (language === DEFAULT_LANGUAGE) {
        defaultData = data;
      }
    }

    return defaultData!;
  }, []);

  return {
    copyInitialData
  };
};
