import { Topic } from "../stores/coreDataV2/coreData-v2-types";

// For now let's use the tag instead of the sort order as we don't want to mess with the excel sheet
const TOPICS_SORT_ORDER = [
    '0004', //'ADAS',
    '0001', // 'Motion',
    '0002', //'Energy',
    '0006', //'Application software',
    '0003', //'Services',
    '0005', //'Compute',
    '0007', //'Semiconductors'
  ];
  
  export const sortTopics = (topics?: Topic[]) => {
    if (!topics) return topics;
    
    return [...topics].sort((topicA: Topic, topicB: Topic) => {
      const aIndex = TOPICS_SORT_ORDER.indexOf(topicA.tag);
      const bIndex = TOPICS_SORT_ORDER.indexOf(topicB.tag);
    
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
    
      return aIndex - bIndex;
    });
  };