import { useTranslation } from "react-i18next";
import { useIonAlert } from "@ionic/react";

export const useAlerts = () => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();

  const presentUpdateAvailableAlert = (
    okHandler: () => void | Promise<any>
  ) => {
    return presentAlert({
      header: t("updateAlert.updateAvailable__title"),
      message: t("updateAlert.updateAvailable__message"),
      buttons: [
        t("updateAlert.updateAvailable__buttonCancel"),
        {
          text: t("updateAlert.updateAvailable__buttonOk"),
          handler: okHandler,
        },
      ],
    });
  };

  const presentNoUpdateAvailableAlert = () => {
    return presentAlert({
      header: t("updateAlert.noUpdateAvailable__title"),
      message: t("updateAlert.noupdateAvailable__message"),
      buttons: [t("updateAlert.noUpdateAvailable__buttonOk")],
    });
  };

  const presentNoInternetConnectionAlert = () => {
    return presentAlert({
      header: t("noInternetAlert.noInternetConnectionAvailable__title"),
      message: t("noInternetAlert.noInternetConnectionAvailable__message"),
      buttons: [t("noInternetAlert.noInternetConnectionAvailable__buttonOk")],
    });
  };

  const presentFirstLaunchAlert = (
    cancelHandler: () => void | Promise<void>,
    okHandler: (value: any) =>
      | boolean
      | void
      | {
          [key: string]: any;
        }
  ) => {
    return presentAlert({
      backdropDismiss: false,
      header: t("updateAlert.updateAvailableFirstLaunch__title"),
      message: t("updateAlert.updateAvailableFirstLaunch__message"),
      buttons: [
        {
          text: t("updateAlert.updateAvailableFirstLaunch__buttonCancel"),
          handler: cancelHandler,
        },
        {
          text: t("updateAlert.updateAvailableFirstLaunch__buttonOk"),
          handler: okHandler,
        },
      ],
    });
  };

  const presentConfirmDeletionAlert = (
    baseKey: string,
    okHandler: () => void
  ) => {
    return presentAlert({
      header: t(`${baseKey}__header`),
      message: t(`${baseKey}__message`),
      buttons: [
        t(`${baseKey}__buttonCancel`),
        {
          text: t(`${baseKey}__buttonOk`),
          handler: okHandler,
        },
      ],
    });
  };

  const presentDeleteContentSuccessAlert = () => {
    return presentAlert({
      header: t(`deleteAlert.deleteContentSuccess__header`),
      message: t(`deleteAlert.deleteContentSuccess__message`),
      buttons: [t(`deleteAlert.deleteContentSuccess__buttonOk`)],
    });
  };

  const presentDeleteContentFailureAlert = () => {
    return presentAlert({
      header: t(`deleteAlert.deleteContentFailure__header`),
      message: t(`deleteAlert.deleteContentFailure__message`),
      buttons: [t(`deleteAlert.deleteContentFailure__buttonOk`)],
    });
  };

  const presentDeleteAssetsSuccessAlert = () => {
    return presentAlert({
      header: t(`deleteAlert.deleteAssetsSuccess__header`),
      message: t(`deleteAlert.deleteAssetsSuccess__message`),
      buttons: [t(`deleteAlert.deleteAssetsSuccess__buttonOk`)],
    });
  };
  const presentDeleteAssetsFailureAlert = () => {
    return presentAlert({
      header: t(`deleteAlert.deleteAssetsFailure__header`),
      message: t(`deleteAlert.deleteAssetsFailure__message`),
      buttons: [t(`deleteAlert.deleteAssetsFailure__buttonOk`)],
    });
  };

  const presentSendingDiagnosticDataFailureAlert = () => {
    return presentAlert({
      header: t(`diagnosticAlert.sendingDiagnosticDataFailure__header`),
      message: t(`diagnosticAlert.sendingDiagnosticDataFailure__message`),
      buttons: [t(`diagnosticAlert.sendingDiagnosticDataFailure__buttonOk`)],
    });
  };

  const presentSendingDiagnosticDataSuccessAlert = () => {
    return presentAlert({
      header: t(`diagnosticAlert.sendingDiagnosticDataSuccess__header`),
      message: t(`diagnosticAlert.sendingDiagnosticDataSuccess__message`),
      buttons: [t(`diagnosticAlert.sendingDiagnosticDataSuccess__buttonOk`)],
    });
  };

  const presentRestartAppToTakeEffectAlert = () => {
    return presentAlert({
      header: t(`restartAlert.restartToTakeEffect__header`),
      message: t(`restartAlert.restartToTakeEffect__message`),
      buttons: [t(`restartAlert.restartToTakeEffect__buttonOk`)],
    });
  };

  return {
    presentUpdateAvailableAlert,
    presentNoUpdateAvailableAlert,
    presentConfirmDeletionAlert,
    presentDeleteContentSuccessAlert,
    presentDeleteContentFailureAlert,
    presentDeleteAssetsSuccessAlert,
    presentDeleteAssetsFailureAlert,
    presentFirstLaunchAlert,
    presentSendingDiagnosticDataFailureAlert,
    presentSendingDiagnosticDataSuccessAlert,
    presentRestartAppToTakeEffectAlert,
    presentNoInternetConnectionAlert
  };
};
