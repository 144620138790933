import create from 'zustand';
import {UserDefaultsState} from './useUserDefaults.types';
import produce from 'immer';
import {persist} from 'zustand/middleware';
import {
    DEFAULT_LANGUAGE,
    AVAILABLE_LANGUAGES,
    LOCATION_ENVIRONMENT, LocationEnvironment,
} from '../../constants/defaults';
import pkgJson from '../../../package.json';
import {isNative} from '../../utils/platform';

export const useUserDefaultsStore = create<UserDefaultsState>()(
    persist(
        (set, get) => ({
            appVersion: pkgJson.version || '2.0.0',
            firstRun: true,
            locationEnvironment: LOCATION_ENVIRONMENT,
            language: {
                current: DEFAULT_LANGUAGE,
                available: AVAILABLE_LANGUAGES,
            },
            environment: null,

            dataDirectoryBasePath: `${process.env.REACT_APP_BASE_URL_DATA}/` || null,
            showSettingsDialog: false,
            showChangingLanguageOverlay: false,

            availableData: {},

            setShowSettingsDialog: (show: boolean) => {
                set(
                    produce((draft) => ({
                        ...draft,
                        showSettingsDialog: show,
                    }))
                );
            },

            setShowChangingLanguageOverlay: (show: boolean) => {
                set(
                    produce((draft) => ({
                        ...draft,
                        showChangingLanguageOverlay: show,
                    }))
                );
            },

            getCoreDataPathsForLanguage: (language: string): { local: string | null; remote: string | null } | null => {
                const filePathsForLanguage = get().availableData[language];
                return filePathsForLanguage ? filePathsForLanguage : null;
            },

            getUpdateAvailable: (): boolean => {
                return Object.entries(get().availableData).some(([, info]) => info.updateAvailable);
            },

            setAppVersion: (version: string) =>
                set(
                    produce((draft) => {
                        draft.appVersion = version;
                    })
                ),

            setAvailableData: ({language, local, remote}: {
                language: string;
                local?: string | null;
                remote?: string | null
            }) => {
                set(
                    produce((draft) => {
                        if (!draft.availableData[language]) {
                            draft.availableData[language] = {local: null, remote: null, updateAvailable: false};
                        }

                        if (typeof local !== 'undefined') {
                            draft.availableData[language].local = local;
                        }
                        if (typeof remote !== 'undefined') {
                            draft.availableData[language].remote = remote;
                        }

                        draft.availableData[language].updateAvailable = draft.availableData[language].local !== draft.availableData[language].remote?.split('/').pop();
                    })
                );
            },

            removeAvailableData: (language: string) => {
                set(
                    produce((draft) => {
                        delete draft.availableData[language];
                    })
                );
            },

            /// first run
            setFirstRun: (firstRun: boolean) =>
                set(
                    produce((draft) => {
                        draft.firstRun = firstRun;
                    })
                ),

            setEnvironment: (environment: string) =>
                set(
                    produce((draft) => {
                        draft.environment = environment;
                    })
                ),

            setLocationEnvironment: (environment: LocationEnvironment) =>
                set(
                    produce((draft) => {
                        draft.locationEnvironment = environment;
                    })
                ),

            /// Language
            setCurrentLanguage: (language: string) =>
                set(
                    produce((draft) => {
                        draft.language.current = language;
                    })
                ),

            addLanguage: (language: string) =>
                set(
                    produce((draft) => {
                        if (!draft.language.available.includes(language)) {
                            draft.language.available.push(language);
                        }
                    })
                ),

            removeLanguage: (language: string) =>
                set(
                    produce((draft) => {
                        draft.language.available = draft.language.available.filter((al: string) => al !== language);
                    })
                ),

            resetLanguages: () =>
                set(
                    produce((draft) => {
                        draft.language.available = [DEFAULT_LANGUAGE];
                    })
                ),

            /// Data Directory Base Path
            setDataDirectoryBasePath: (basePath: string) => {
                set(
                    produce((draft) => {
                        draft.dataDirectoryBasePath = basePath;
                    })
                );
            },
        }),
        {
            name: 'SPACE_CAR_V2_userDefaults',
            partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['showSettingsDialog'].includes(key))),
            getStorage: () => (isNative ? localStorage : sessionStorage),
        }
    )
);
