const parseConfigFileName = (fileName: string, fallbackText?: string) => {
  if (!fileName) {
    return fallbackText ? fallbackText : null;
  }

  if (fileName.startsWith('http')) {
   return fileName.split('/').pop()?.replace('.json', ''); 
  }

  return fileName.split('schemas/').pop()?.replace('.json', '');
};

export { parseConfigFileName };
