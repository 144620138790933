import { IonContent, IonPage } from '@ionic/react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import { forwardRef, ReactNode } from 'react';
import { isNative } from "../utils/platform";

const Section = styled.section((props: any) => ({
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: props.backgroundPosition,
}));

export type BaseGridContainerProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  mostInnerClassName?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  headline: string;
  headerSlotRight?: ReactNode;
  headerSlotLeft?: ReactNode;
};

export const BaseGridContainer = forwardRef(
  (
    {
      children,
      backgroundImage,
      backgroundPosition,
      className,
      innerClassName,
      mostInnerClassName,
      headline,
      headerSlotRight,
      headerSlotLeft,
      ...props
    }: BaseGridContainerProps,
    ref
  ) => {
    return (
      <IonPage>
        <IonContent fullscreen forceOverscroll={false}>
          <Section
            backgroundImage={backgroundImage}
            backgroundPosition={backgroundPosition || 'right'}
            className={cx('bg-bosch-light-grey-w50 absolute inset-0', !isNative && 'mx-auto max-h-full', className)}
            ref={ref}
          >
            <div className={cx('absolute left-8 right-8 bottom-8 top-0', innerClassName)}>
              <div className={cx('base-grid-container')}>
                <header className="base-grid-container__headline flex w-full justify-between">
                  <div>
                    <h2 dangerouslySetInnerHTML={{ __html: headline }} className="bosch-text-headline text-3xl" />
                    {headerSlotLeft && <div className="text-lg font-normal">{headerSlotLeft}</div>}
                  </div>
                  <div>{headerSlotRight}</div>
                </header>
                <div className={cx('base-grid-container__stage', mostInnerClassName)}>{children}</div>
              </div>
            </div>
          </Section>
        </IonContent>
      </IonPage>
    );
  }
);
