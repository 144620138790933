import { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import '@ionic/react/css/core.css';

import './utils/ionicReactStyles.setup';
import './utils/swiper.setup';
import { Home } from './pages/home/Home';
import { SettingsDataPage, SettingsDebugPage, SettingsPage } from './components/Settings';
import { routes } from './utils/routes';
import { ServicesOverview } from './pages/services-overview/services-overview';
import { Overview } from './pages/overview/Overview';
import { ComponentsOverview } from './pages/components-overview/components-overview';
import { ComponentDetail } from './pages/component-detail/component-detail';
import { ServicesOverviewLevelTwo } from './pages/services-overview/services-overview-level-two';
import {ServicesOverviewLevelThree} from "./pages/services-overview/services-overview-level-three";
import { DetailsWithFlagsAndModal } from './pages/details-with-flags-and-modal/details-with-flags-and-modal';
import { IonReactRouter } from '@ionic/react-router';
import { Scaffold } from './Scaffold';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast';

setupIonicReact({ mode: 'ios' });
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always' // FUCK THIS SETTING!
    }
  }
})

const App: FC = () => {
  return (
    <>
    <IonApp>
      <IonReactRouter>
        <QueryClientProvider client={queryClient} >
          <Scaffold>
            <IonRouterOutlet>
              <Route exact key="home-page" path={routes.home} component={Home} />
              <Route exact key="overview-page" path={routes.overview} component={Overview} />
              <Route exact key="services-overview-page" path={routes.services__overview} component={ServicesOverview} />
              <Route exact key="services-overview-nested-page" path={routes.services__overview_nested} component={ServicesOverviewLevelTwo} />
              <Route exact key="services-overview-nested-page-level-three" path={routes.services__overview_nested_level_three} component={ServicesOverviewLevelThree} />
              <Route exact key="components-overview-page" path={routes.components__overview} component={ComponentsOverview} />
              <Route exact key="component-detail-page" path={routes.component__detail} component={ComponentDetail} />
              <Route exact key="details-flag-modal-page" path={routes.details__flags_and_modal} component={DetailsWithFlagsAndModal} />
              <Route exact key="settings-page" path={routes.settings} component={SettingsPage} />
              <Route exact key="settings-data-page" path={routes.settings__data} component={SettingsDataPage} />
              <Route exact key="settings-debug-page" path={routes.settings__debug} component={SettingsDebugPage} />
              <Route exact path={routes.root}>
                <Redirect to={routes.home} />
              </Route>
            </IonRouterOutlet>
          </Scaffold>
        </QueryClientProvider>
      </IonReactRouter>
    </IonApp>
    <Toaster containerClassName='z-[10000000]'/>
    </>
  );
};

export default App;
