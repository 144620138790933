import axios from 'axios';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { useOnline } from 'rooks';
import { useDownloadManager } from '../../hooks/download-manager/useDownloadManager';
import { useDownloadStatsStore } from '../../stores/downloadStats/useDownloadStats.store';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { AssetMetaItem, CoreDataV2 } from '../../stores/coreDataV2/coreData-v2-types';
import {FILESYSTEM_PREFIX} from "../../constants/files-directories";

export interface ExtendedAssetsMetaItem extends AssetMetaItem { path: string; fileName: string }

export const useSettings = () => {
  const userDefaultsStore = useUserDefaultsStore();
  const { downloadContent } = useDownloadManager();
  const isOnline = useOnline();
  const { updateCurrentState } = useDownloadStatsStore();

  const fetchRemoteVersionInfo = async (): Promise<boolean> => {
    try {
      const { data: versions } = await axios.get('/versions', {
        baseURL: process.env.REACT_APP_BASE_URL_API,
        headers: {
          'x-environment': userDefaultsStore.environment || process.env.REACT_APP_ENVIRONMENT
        }
      });
  
      for (const [language, url] of Object.entries<string>(versions)) {
        userDefaultsStore.setAvailableData({ language, remote: url });
      }
  
      return userDefaultsStore.getUpdateAvailable();
    } catch (e) {
      return false;
    }
  };

  const checkForUpdates = async (): Promise<boolean> => {
    if (!isOnline) {
      return false;
    }

    try {
      const isUpdateAvailable = await fetchRemoteVersionInfo();
      updateCurrentState(isUpdateAvailable ? 'UPDATE_AVAILABLE' : 'IDLE');
      return isUpdateAvailable;
    } catch (e) {
      // TODO: Add error message
      updateCurrentState('IDLE');
      return false;
    }
  };

  const gatherUpdateInfo = async (): Promise<{
    assetsNotDownloaded: AssetMetaItem[];
    totalBytes: number;
  }> => {
    const environment = userDefaultsStore.environment || process.env.REACT_APP_ENVIRONMENT as string;
    const allDownloadedContent: CoreDataV2[] = [];
    
    // TODO: Make this work with the versions endpoint
    for (const [language, versionInfo] of Object.entries(useUserDefaultsStore.getState().availableData)) {
      if (!versionInfo.remote) {
        console.info('remote is null 🤔');
      } else {
        if (versionInfo.updateAvailable) {
          const downloadedContent = await downloadContent([language, versionInfo.remote], environment);
          allDownloadedContent.push(downloadedContent as CoreDataV2);
        }
      }
    }

    
    const allAssets: AssetMetaItem[] = allDownloadedContent
      .map(({ meta: {assets: { items }} }) => items!).reduce((acc, val) => acc.concat(val), [])
    
    const uniqueAssets = allAssets.filter((data, index, self) =>
      index === self.findIndex((t) => (
        t.url === data.url
      ))
    );

    const extendedUniqueAssets: ExtendedAssetsMetaItem[] = [];
    uniqueAssets.forEach(({ url, sizeKbs }: any) => {
      const fullUrlObj = new URL(url);
      const path = fullUrlObj.pathname.split('/').slice(0, -1).join('/');
      const fileName = fullUrlObj.pathname.substring(fullUrlObj.pathname.lastIndexOf('/') + 1);
      extendedUniqueAssets.push({ url, path, fileName, sizeKbs });
    });

    let assetsNotDownloaded: AssetMetaItem[] = [];

    const assetsDirContents = await Filesystem.readdir({
      directory: Directory.Documents,
      path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment}/assets/`
    });

    for (const file of extendedUniqueAssets) {
      try {
        file.url = file.url.replace(' ', '%20')
        if (!assetsDirContents.files.some(fileInfo => fileInfo.name === file.fileName.replace(' ', '%20'))) {
          assetsNotDownloaded.push(file);
        }
      } catch (e) {
        assetsNotDownloaded.push(file);
      }
    }

    const totalBytesToDownload = assetsNotDownloaded.map(({ sizeKbs }) => sizeKbs || 0).reduce((acc, curr) => acc + curr, 0) * 1024;

    return {
      assetsNotDownloaded,
      totalBytes: totalBytesToDownload,
    };
  };

  const downloadUpdates = async (): Promise<void> => {
    updateCurrentState('DOWNLOADING');
  };

  return {
    fetchRemoteVersionInfo,
    downloadUpdates,
    gatherUpdateInfo,
    checkForUpdates,
  };
};
