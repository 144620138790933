export enum LocationEnvironment {
    'GLOBAL',
    'UAES'
}
export const getSubdomain = () => {
    // Check for a manual override via a query parameter first
    const urlParams = new URLSearchParams(window.location.search);
    const subdomainParam = urlParams.get('subdomain');
    if (subdomainParam) {
        return subdomainParam;
    }

    // If no override, extract the subdomain from the hostname
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
        return hostname;
    }

    const subdomainParts = hostname.split('.');
    // Assuming the subdomain is always the first part of the hostname
    return subdomainParts.length > 2 ? subdomainParts[0] : null;
};

const subdomain = getSubdomain();
export const isUAESubdomain = subdomain?.includes('cn') || subdomain === 'localhost'; // Assuming 'cn' is the subdomain for UAES

export const LOCATION_ENVIRONMENT = isUAESubdomain ? LocationEnvironment.UAES : LocationEnvironment.GLOBAL;
export const DEFAULT_LANGUAGE = isUAESubdomain
    ? process.env.REACT_APP_DEFAULT_LANGUAGE_UAES || 'en'
    : process.env.REACT_APP_DEFAULT_LANGUAGE_GLOBAL || 'en';

export const AVAILABLE_LANGUAGES = isUAESubdomain
    ? process.env.REACT_APP_AVAILABLE_LANGUAGES_UAES?.split(',') || ['en', 'cn']
    : process.env.REACT_APP_AVAILABLE_LANGUAGES_GLOBAL?.split(',') || ['en', 'jp'];
export const INITIAL_FILES: { [key: string]: string } = {
    en: '948e23a7-486b-427a-ac92-90731dc27ebc.json',
    jp: 'badd49a2-6302-48f0-a446-b26a8e15a0f0.json',
}


