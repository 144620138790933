import { useCallback, useEffect, useReducer, useRef } from 'react';
import debounce from 'lodash.debounce';
import { cover, IntrinsicScale } from 'intrinsic-scale';
import { defaults } from '../../static-data/defaults';
import { FlagProps, ExtendedPosition } from '../../components/flag/flag';
import { Flag } from '../../stores/coreDataV2/coreData-v2-types';

const calculateInitialCanvasPosition = (pos: ExtendedPosition, intrinsicScale: IntrinsicScale, scale: number): Pick<ExtendedPosition, 'x' | 'y'> => {
  let x = pos.x;
  let y = pos.y;

  switch (pos.direction) {
    case 'DOWN':
    case 'DOWN-LEFT':
      x = pos.x * scale + intrinsicScale.x;
      y = pos.y * scale + intrinsicScale.y + pos.length;
      break;
    case 'UP':
    case 'UP-LEFT':
      x = pos.x * scale + intrinsicScale.x;
      y = pos.y * scale + intrinsicScale.y - pos.length;
      break;
    case 'LEFT':
      x = pos.x * scale + intrinsicScale.x - pos.length;
      y = pos.y * scale + intrinsicScale.y;
      break;
    case 'RIGHT':
      x = pos.x * scale + intrinsicScale.x + pos.length;
      y = pos.y * scale + intrinsicScale.y;
      break;
  }

  return { x, y };
};

const calculateInitialCanvasSize = (x: number, y: number, text: string, paddingY = 16, paddingX = 24) => {
  const textElement = document.createElement('span');
  document.body.appendChild(textElement);

  textElement.style.font = 'boschsans';
  textElement.style.fontWeight = '700';
  textElement.style.fontSize = '16px';
  textElement.style.height = 'auto';
  textElement.style.width = 'auto';
  textElement.style.position = 'absolute';
  textElement.style.whiteSpace = 'no-wrap';
  textElement.innerHTML = text;

  const width = Math.ceil(textElement.clientWidth + paddingX);
  const height = Math.ceil(textElement.clientHeight + paddingY);

  document.body.removeChild(textElement);
  return { width, height };
};

const isOverlapping = (flagA: FlagProps, flags: FlagProps[]): boolean => {
  if (!flagA || !flags.length) {
    return false;
  }

  const flagsWithoutA = flags.filter((flag) => flag.id !== flagA.id);

  let leftA = flagA.position.x - 5;
  let rightA = flagA.position.x - 5 + flagA.position.width! + 5;
  const topA = flagA.position.y - 5;
  const bottomA = flagA.position.y - 5 + flagA.position.height! + 5;

  if (flagA.position.direction.includes('LEFT')) {
    leftA = flagA.position.x - 5 - flagA.position.width!;
    rightA = flagA.position.x - 5;
  }

  return flagsWithoutA.some((flagB) => {
    let leftB = flagB.position.x - 5;
    let rightB = flagB.position.x - 5 + flagB.position.width! + 5;

    if (flagB.position.direction.includes('LEFT')) {
      leftB = flagB.position.x - 5 - flagB.position.width!;
      rightB = flagB.position.x - 5;
    }

    const topB = flagB.position.y - 5;
    const bottomB = flagB.position.y - 5 + flagB.position.height! + 5;

    // check for overlap between the rectangles
    const overlapX = Math.max(0, Math.min(rightA, rightB) - Math.max(leftA, leftB));
    const overlapY = Math.max(0, Math.min(bottomA, bottomB) - Math.max(topA, topB));

    return overlapX > 0 && overlapY > 0;
  });
};

const initialState = {
  flags: [],
  flagsWithChildren: [],
};

const flagsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_FLAGS':
      return {
        ...state,
        flags: action.payload.flags,
      };
    case 'SET_FLAGS_WITH_CHILDREN':
      return {
        ...state,
        flagsWithChildren: action.payload.flagsWithChildren,
      };
    default:
      return state;
  }
};

export const useFlags = (flags: Flag[], imageContainerRef: any) => {
  const resizeRef = useRef<boolean>(false);

  const [state, dispatch] = useReducer(flagsReducer, initialState);

  const positionFlags = useCallback(
    debounce(() => {
      if (flags && imageContainerRef?.current) {
        const flagContent = flags;

        const width = imageContainerRef?.current?.getBoundingClientRect()?.width;
        const height = imageContainerRef?.current?.getBoundingClientRect()?.height;
        const coverResult = cover(width, height, defaults.imageDimensions.width, defaults.imageDimensions.height);
        const scale = (100 / defaults.imageDimensions.width) * coverResult.width;

        const translatedScale = {
          scale: scale / 100,
          coverResult,
        };

        const regularFlags: Flag[] = [];
        const flagsWithChildren: Flag[] = [];

        const flagsWithPositionsAndDimensions = [...flagContent]?.map((flag: any, idx: number) => {
          const isNotPositioned = flag.position.y === 1812 && flag.position.x === 3012
          const initialCanvasPosition = calculateInitialCanvasPosition(flag.position, translatedScale.coverResult, translatedScale.scale);

          const size = calculateInitialCanvasSize(initialCanvasPosition.x, initialCanvasPosition.y, flag.component?.label);
        
          return {
            ...flag,
            position: {
              ...flag.position,
              ...initialCanvasPosition,
              ...size,
              isNotPositioned,
            },
          };
        });

        const flagsWithChildrenIds = new Set();
        const fff = flagsWithPositionsAndDimensions.map((flag) => {
          flag.overlapping = isOverlapping(flag, flagsWithPositionsAndDimensions);
          if (flag?.groupedByFlagId && !flagsWithChildrenIds.has(flag?.groupedByFlagId)) {
            flagsWithChildrenIds.add(flag?.groupedByFlagId);
          }

          if (flag.overlapping) {
            let counter = 0;
            flag.position.overlapOffset = 0;
            while (isOverlapping(flag, flagsWithPositionsAndDimensions)) {
              counter++;
              if (flag.position.direction.includes('UP')) {
                flag.position.y = flag.position.y - 1;
                flag.position.overlapOffset = flag.position.overlapOffset + 1;
              } else if (flag.position.direction.includes('DOWN')) {
                flag.position.y = flag.position.y + 1;
                flag.position.overlapOffset = flag.position.overlapOffset + 1;
              } else if (flag.position.direction === 'LEFT') {
                flag.position.x = flag.position.x - 1;
                flag.position.overlapOffset = flag.position.overlapOffset + 1;
              } else if (flag.position.direction === 'RIGHT') {
                flag.position.x = flag.position.x + 1;
                flag.position.overlapOffset = flag.position.overlapOffset + 1;
              }

              if (counter > 200) {
                break;
              }
            }
            flag.overlapping = false;
          }

          return flag;
        });

        fff.sort((a: Flag, b: Flag) => {
          if (a.position.direction.includes('UP') && b.position.direction.includes('UP')) {
            return a.position.y - b.position.y;
          } else {
            return b.position.y - a.position.y;
          }
        });

        
        setTimeout(() => {
          const parked: Flag[] = [];
          fff.forEach((flag: Flag) => {
          
            if (flagsWithChildrenIds.has(flag.id)) {
              flag.children = [];              
            }
            
            if (flag.groupedByFlagId) {
              parked.push(flag);
            } else {
              regularFlags.push(flag);
            }
          });

          parked.forEach((flag: Flag) => {
            const parentFlag = regularFlags.find((regularFlag: Flag) => regularFlag.id === flag.groupedByFlagId);
            if (parentFlag) {
              parentFlag.children?.push(flag);
            }
          });

          dispatch({
            type: 'SET_FLAGS',
            payload: {
              flags: regularFlags,
            },
          });

          dispatch({
            type: 'SET_FLAGS_WITH_CHILDREN',
            payload: {
              flagsWithChildren,
            },
          });
        }, 1000);
      }
    }, 100),
    [flags, imageContainerRef?.current]
  );

  useEffect(() => {
    if (!resizeRef.current) {
      window.addEventListener('resize', positionFlags);
      window.dispatchEvent(new Event('resize'));
      resizeRef.current = true;
    }
    return () => {
      window.removeEventListener('resize', positionFlags);
      resizeRef.current = false;
    };
  }, [positionFlags, flags, imageContainerRef?.current]);

  return state;
};
