import React, {FC, PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useOnStartup} from './hooks/onStartup';
import {useLocation} from 'react-router';
import {useIonRouter} from '@ionic/react';
import {useAlerts} from './hooks/useAlert';
import {SettingsDataPage, useSettings} from './components/Settings';
import {useUserDefaultsStore} from './stores/userDefaults/useUserDefaults.store';
import debounce from 'lodash.debounce';
import axios from 'axios';
import {APP_PLATFORM, isNative} from "./utils/platform";
import {Directory, Encoding, Filesystem} from '@capacitor/filesystem';
import {FILESYSTEM_PREFIX} from './constants/files-directories';
import {isDebugMode} from './utils/debug.utils';
import qs from 'qs';
import {NavbarComponent} from './components/Navbar';
import {ReactComponent as RotationIcon} from './assets/icons/rotation.svg';
import {useCoreDataV2Store} from './stores/coreDataV2/useCoreDataV2Store.store';
import * as Dialog from '@radix-ui/react-dialog';
import {isUAESubdomain, LOCATION_ENVIRONMENT} from "./constants/defaults";

export const Scaffold: FC<PropsWithChildren<any>> = ({children}) => {
    const location = useLocation();
    const router = useIonRouter();
    const {presentFirstLaunchAlert, presentUpdateAvailableAlert} = useAlerts();
    const {t, i18n} = useTranslation();
    const userDefaultsStore = useUserDefaultsStore();
    const [showChangeLanguageOverlay, setShowChangeLanguageOverlay] = useState(false);
    const [showRotateDeviceHint, setShowRotateDeviceHint] = useState(false);

    const {init} = useOnStartup();
    const {fetchRemoteVersionInfo} = useSettings();
    const isMounted = useRef(false);
    const {coreData: {language}, setCoreData} = useCoreDataV2Store();

    const goBack = () => {
        const canGoBack = router.canGoBack();
        if (canGoBack) {
            router.goBack();
            setTimeout(() => {
                goBack();
            }, 0);
        }
    };

    const onResize = useCallback(
        debounce(() => {
            if (window.matchMedia('(orientation: portrait)').matches) {
                setShowRotateDeviceHint(true);
            } else {
                setShowRotateDeviceHint(false);
            }
        }, 100),
        []
    );

    const changeLanguage = async (languageToChangeTo: string) => {
        if (language !== languageToChangeTo && isMounted.current) {
            const paths = userDefaultsStore.getCoreDataPathsForLanguage(languageToChangeTo);
            if (APP_PLATFORM === "NATIVE") {
                // Handle native
                if (paths?.local) {
                    setShowChangeLanguageOverlay(true);
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    goBack();

                    const file = await Filesystem.readFile({
                        directory: Directory.Documents,
                        path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment}/schemas/${paths.local}`,
                        encoding: Encoding.UTF8,
                    });
                    setCoreData(JSON.parse(file.data as string));

                    await i18n.changeLanguage(languageToChangeTo);
                } else {
                    console.error(`No file found for language ${languageToChangeTo}`);
                }
                await new Promise((resolve) => setTimeout(resolve, 2300));
                setShowChangeLanguageOverlay(false);
            } else {
                // Handle web
                setShowChangeLanguageOverlay(true);
                await new Promise((resolve) => setTimeout(resolve, 500));
                window.location.href = '/';
            }
        }
        isMounted.current = true;
    };

    useEffect(() => {
        const newLanguage = userDefaultsStore.language.current
        changeLanguage(newLanguage);
        document.documentElement.lang = newLanguage;
        let root = document.documentElement;

        if (newLanguage === 'jp') {
            root.style.setProperty('--ion-font-family', 'boschsans-jp');
        } else if (newLanguage === 'cn') {
            root.style.setProperty('--ion-font-family', 'boschsans-zh');
        } else {
            root.style.setProperty('--ion-font-family', 'boschsans');
        }
    }, [userDefaultsStore.language.current]);

    useEffect(() => {
        const run = async (isNative: boolean) => {
            const {firstRun} = await init(isNative);

            if (isNative) {
                fetchRemoteVersionInfo().then(updateAvailable => {
                    if (updateAvailable) {
                        presentUpdateAvailableAlert(() => {
                            userDefaultsStore.setShowSettingsDialog(true);
                        });
                    }
                });

                if (firstRun) {
                    await presentFirstLaunchAlert(
                        () => {
                        },
                        () => {
                            userDefaultsStore.setShowSettingsDialog(true);
                        }
                    );
                } else {
                    console.info('NOT first run detected');
                    const availableDataForLanguage = userDefaultsStore.getCoreDataPathsForLanguage(userDefaultsStore.language.current);

                    if (availableDataForLanguage?.local) {
                        try {
                            const file = await Filesystem.readFile({
                                directory: Directory.Documents,
                                path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment}/schemas/${availableDataForLanguage?.local}`,
                                encoding: Encoding.UTF8
                            });
                            await i18n.changeLanguage(userDefaultsStore.language.current);
                            setCoreData(JSON.parse(file.data as string));
                        } catch (e) {
                            console.error(e);
                            console.error('Failed parsing local data');
                        }
                    } else {
                        console.error(`Could not find local data for language ${userDefaultsStore.language.current}`);
                    }
                }
            } else {
                const environment = qs.parse(location.search?.substring(1))?.environment as string || isUAESubdomain ? `${process.env.REACT_APP_ENVIRONMENT}-uaes` : process.env.REACT_APP_ENVIRONMENT as string;

                userDefaultsStore.setEnvironment(environment);
                userDefaultsStore.setLocationEnvironment(LOCATION_ENVIRONMENT);

                const {data: versions} = await axios.get('/versions', {
                    baseURL: process.env.REACT_APP_BASE_URL_API,
                    headers: {
                        'x-environment': environment
                    }
                });

                if (Object.keys(versions)?.length === 0) {
                    window.alert('No versions found, please use another environment');
                    return;
                }

                const url = isUAESubdomain ? versions[`${userDefaultsStore.language.current}-uaes`] : `${versions[userDefaultsStore.language.current]}`;
                const {data} = await axios.get(url);

                for (const [language, remote] of Object.entries<string>(versions)) {
                    const normalizedLanguage = language.replace('-uaes', '');
                    const normalizedRemote = remote.replace('-uaes', '');
                    userDefaultsStore.setAvailableData({language: normalizedLanguage, remote: normalizedRemote});
                    if (userDefaultsStore.language.available.indexOf(normalizedLanguage) === -1) {
                        userDefaultsStore.addLanguage(normalizedLanguage);
                    }
                }
                await i18n.changeLanguage(userDefaultsStore.language.current);
                setCoreData(data);
            }
        };

        document.documentElement.classList.add(isNative ? 'is-native' : 'is-web');

        run(isNative);

        return () => {
            if (!isNative) {
                document.documentElement.classList.remove('is-web');
                document.documentElement.classList.remove('is-native');
            }
        };
    }, []);

    // useEffect(() => {
    //   changeLanguage(userDefaultsStore.language.current);
    // }, [userDefaultsStore.language.current]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    /*
    useEffect(() => {
      if (!isNative) {
        setTimeout(() => {
          i18n.changeLanguage('de');
        }, 1000);
      }
    }, [isNative]);
  */

    return (
        <div>
            {isDebugMode() && (
                <div
                    className="fixed bottom-0 right-0 z-10 flex divide-x bg-red-700 px-2 text-sm font-semibold text-white">
                    {/* <span className="p-2">Version: {fileName?.split('_')[0]}</span> */}
                    <span className="p-2">Route: {location.pathname}</span>
                    <span
                        className="p-2">Node: {qs.parse(location.search?.substring(1))?.node as string || 'none'}</span>
                </div>
            )}

            {!isNative && !navigator?.onLine && (
                <div
                    className="fixed left-0 top-0 right-0 z-[999] flex justify-center bg-bosch-red p-6 text-white">{t('offlineHint') as string}</div>
            )}

            {showRotateDeviceHint && (
                <div
                    className="fixed inset-0 z-[999] flex flex-col items-center justify-center gap-6 bg-bosch-light-gray-w50 p-6 text-center text-bosch-dark-gray-b50">
                    <RotationIcon className="block h-12 w-12"/>
                    <p className="font-bold">{t('rotateDeviceHint') as string}</p>
                </div>
            )}

            <NavbarComponent/>

            <div className="absolute inset-0 top-[50px]">{children}</div>

            {showChangeLanguageOverlay && (
                <div
                    className="!pointer-events-none fixed !top-0 !bottom-0 !right-0 !left-0 !z-50 !m-0 flex h-full w-full flex-col items-center justify-center !bg-black !p-0 text-center !text-white">
                    {t('generic.changingLanguage__label') as string}
                </div>
            )}

            <Dialog.Root open={userDefaultsStore.showSettingsDialog}>
                <Dialog.Trigger/>
                <Dialog.Portal>
                    <Dialog.Overlay>
                        <Dialog.Content
                            className="absolute !top-0 !bottom-0 !right-0 !left-0 !z-0 !m-0 flex h-full w-full flex-col !p-0 !text-black">
                            <SettingsDataPage/>
                        </Dialog.Content>
                    </Dialog.Overlay>
                </Dialog.Portal>
            </Dialog.Root>
        </div>
    );
};
