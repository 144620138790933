import { Directory, Filesystem } from '@capacitor/filesystem';
import { useUserDefaultsStore } from '../stores/userDefaults/useUserDefaults.store';
import { useInitialDataHook } from './useInitialData.hook';
import { BASE_DIRECTORIES } from '../constants/files-directories';
import { Capacitor } from '@capacitor/core';
import { useCoreDataV2Store } from '../stores/coreDataV2/useCoreDataV2Store.store';
import {DEFAULT_LANGUAGE} from "../constants/defaults";

export const useOnStartup = () => {
  const userDefaultsStore = useUserDefaultsStore();
  const { setCoreData } = useCoreDataV2Store();
  const { copyInitialData } = useInitialDataHook();

  /* BEGIN ~ fns for the native application */
  const initDataDirectoryBasePath = async (): Promise<void> => {
    try {
      const uri = await Filesystem.getUri({
        path: '',
        directory: Directory.Documents
      });
      userDefaultsStore.setDataDirectoryBasePath(Capacitor.convertFileSrc(uri.uri.slice(0, -1)));
    } catch (e) {
      console.error('Error initializing data directory', e);
    }
  };

  const createDirectories = async () => {
    try {
      for (const path of BASE_DIRECTORIES) {
        await Filesystem.mkdir({
          directory: Directory.Documents,
          path
        });
      }
    } catch (e) {
      console.error('Error occurred while creating base directories', e);
    }
  };
  /* END ~ fns for the native application */

  const init = async (isNative = true): Promise<{ firstRun: boolean }> => {

    // If we're in the web, there's no need to create directories or set the base path.
    // The base path is already being set as the default in the userDefaultsStore.
    if (!isNative) {
      return { firstRun: false };
    }


    await initDataDirectoryBasePath();

    if (userDefaultsStore.firstRun) {
      // 1. First run
      // 1.1. Set data directory base path
      // 1.1. Create base directories
      // 1.2 Copy initial data
      // 1.3 Load the data of the default language into state
      // 1.4 Set the default language in the user defaults
      // await createDirectories();

      const initialData = await copyInitialData();
      setCoreData(initialData);
      userDefaultsStore.setCurrentLanguage(DEFAULT_LANGUAGE as string);
      userDefaultsStore.setFirstRun(false);
      userDefaultsStore.setEnvironment(process.env.REACT_APP_ENVIRONMENT as string);

      return { firstRun: true };
    } else {
      // 1. NOT first run
      // 1.1 Get the current language from the user defaults
      // 1.2 Load the data of the current language from the filesystem into state
      // 1.3 Set the current language in the user defaults
      // console.info('NOT first run detected');
      // const availableDataForLanguage = userDefaultsStore.getCoreDataPathsForLanguage(userDefaultsStore.language.current);
      // console.log(availableDataForLanguage);
      
      // if (availableDataForLanguage?.local) {
      //   try {
      //     const file = await readFile({
      //       directory: Directory.Documents,
      //       path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment}/schemas/${availableDataForLanguage?.local}`,
      //       encoding: Encoding.UTF8
      //     });
      //     setCoreData(JSON.parse(file.data));
      //     await i18n.changeLanguage(userDefaultsStore.language.current);
      //   } catch (e) {
      //     console.error(e);
      //     console.error('Failed parsing local data');
      //   }
      // } else {
      //   console.error(`Could not find local data for language ${userDefaultsStore.language.current}`);
      // }
      return { firstRun: false };
    }
  };

  return {
    init
  };
};
