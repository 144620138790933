import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { SubpageGridContainer } from '../../components/subpage-grid-container';
import { routes } from '../../utils/routes';
import { getSubgridOverviewData } from '../../utils/data-extractor';
import { isDebugMode } from '../../utils/debug.utils';
import { usePageData } from '../../hooks/usePageData/usePageData.hook';
import { useQuery } from '@tanstack/react-query';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';

export const Overview = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { getPageData, nodeRef } = usePageData();
  const { language } = useUserDefaultsStore();
  const { coreData } = useCoreDataV2Store();
  const topicQuery = useQuery(['SPACECAR', coreData?.id, language.current, ...(nodeRef.current?.split('.') || [])], () => getPageData(nodeRef.current as string), {enabled: !!nodeRef.current})


  const serviceButtonOnClick = () => {
    history.push({
        pathname: topicQuery.data?.services?.length === 0
          ? routes.details__flags_and_modal
          : routes.services__overview,
        search: `?node=${nodeRef.current}`
      }
    );
  };

  const componentButtonOnClick = () => {
    history.push({
      pathname: routes.components__overview,
      search: `?node=${nodeRef.current}`
    });
  };


  return (
    <SubpageGridContainer {...getSubgridOverviewData(topicQuery.data)}>
      {isDebugMode() && <div
        className='bg-red-700 text-white fixed left-0 top-0 font-semibold px-2 py-1 z-10'>{topicQuery.data?.id}</div>}
      <IonButton
        fill='solid'
        color='secondary'
        className='subpage-grid-container__button-0 m-0 self-end font-bold h-auto'
        onClick={serviceButtonOnClick}
      >
        <span
          className='flex justify-center items-center w-full whitespace-normal py-2 leading-tight text-sm min-h-[50px]'>
            <span>{t('overview__button')}</span>
        </span>
      </IonButton>
      {topicQuery.data?.flags?.length > 0 && <IonButton
        fill='solid'
        color='secondary'
        className='subpage-grid-container__button-1 m-0 self-end font-bold h-auto'
        onClick={componentButtonOnClick}
      >
        <span
          className='flex justify-center items-center w-full whitespace-normal py-2 leading-tight text-sm min-h-[50px]'>
            <span>{t('components__button')}</span>
        </span>
      </IonButton>}
    </SubpageGridContainer>
  );
};
