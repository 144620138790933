import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { render } from 'react-dom';
//import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import pkgJson from '../package.json';
import './i18n';

import './styles/typography.scss';
import './styles/styles.scss';
import './styles/grids/subpage-grid-container.scss';
import './styles/grids/service-menu-grid.scss';
import './styles/grids/base-grid-container.scss';
import './styles/grids/component-topview-container.scss';
import './styles/grids/component-detail-container.scss';
import { isNative } from './utils/platform';
//import * as Sentry from '@sentry/capacitor';
//import * as SentryReact from '@sentry/react';

// const sentryConfig = {
//   dsn: isNative
//     ? 'https://6197f84fe0884caa98d52dd418af453d@o1199640.ingest.sentry.io/6323514'
//     : 'https://7983b32832164958bb3a35de0da0844e@o1199640.ingest.sentry.io/4504180965507072',
//   release: `bosch-space-car-${isNative ? 'app' : 'web'}@${pkgJson.version}`,
//   dist: '1',
//
//   // @ts-ignore
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// };

//Sentry.init(sentryConfig, SentryReact.init);


const container = document.getElementById('root')!;
const root = createRoot(container); // Create a root.

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
