import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useIonViewDidEnter, useIonViewDidLeave, useIonViewWillLeave } from '@ionic/react';
import { animated, useTransition } from 'react-spring';
import { IoCloseOutline } from '@react-icons/all-files/io5/IoCloseOutline';
import { ComponentDetailContainer } from '../../components/component-detail-container';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import qs from 'qs';
import { ReactComponent as ScrollIndicatorIcon } from '../../assets/icons/scroll-indicator.svg';
import { isDebugMode } from '../../utils/debug.utils';
import { usePageData } from '../../hooks/usePageData/usePageData.hook';
import { useQuery } from '@tanstack/react-query';
import { isNative } from '../../utils/platform';
import { fadeInDownTransitionConfig } from '../../animations/animation';
import { cx } from '@emotion/css';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';

export const ComponentDetail = () => {
  const history = useHistory();
  const location = useLocation();

  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const transitions = useTransition(isOpen, fadeInDownTransitionConfig);

  const [isScrollable, setIsScrollable] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { language, dataDirectoryBasePath } = useUserDefaultsStore();
  const { coreData } = useCoreDataV2Store();
  const { getPageData, nodeRef } = usePageData();


  const serviceQuery = useQuery(['SPACECAR', coreData?.id, language.current, ...(nodeRef.current?.split('.') || [])], () => getPageData(nodeRef.current as string), {enabled: !!nodeRef.current})

  useEffect(() => {
    if (!nodeRef.current) {
      nodeRef.current = (qs.parse(location.search, { ignoreQueryPrefix: true }) as { node: string }).node;
    }
  }, [location.search]);

  // LIFECYCLE METHODS
  useIonViewDidEnter(() => {
    setIsOpen(true);
  }, []);

  useIonViewWillLeave(() => {
    setIsOpen(false);
  }, [isOpen]);

  useIonViewDidLeave(() => {
    if (history.action !== 'PUSH') {
      nodeRef.current = null;
    }
  });

  useEffect(() => {
    if (serviceQuery.data?.background?.url) {
      setBackgroundUrl(isNative ? `${dataDirectoryBasePath}${serviceQuery.data?.background.path}` : serviceQuery.data?.background?.url);
    }
  }, [serviceQuery.data]);

  useEffect(() => {
    setTimeout(() => {
        if (scrollContainerRef?.current) {
          setIsScrollable(scrollContainerRef?.current?.scrollHeight > scrollContainerRef?.current?.clientHeight);
        }
      }, 100)
  }, [scrollContainerRef.current]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      history.goBack();
    }, 330);
  }, []);

  return (
    <ComponentDetailContainer
      backgroundImage={backgroundUrl}
      layout={(serviceQuery.data?.position?.modalPosition as 'LEFT' | 'RIGHT') || 'RIGHT'}
    >
      {isDebugMode() && (
        <div className="fixed left-0 top-0 z-10 bg-red-700 px-2 py-1 font-semibold text-white">
          {serviceQuery.data?.componentId} ({serviceQuery.data?.cmsId})
        </div>
      )}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div className="component-detail-container__detail" style={styles}>
              <button type="button" className="component-detail-container__close-button" onClick={onClose}>
                <IoCloseOutline size="32px" />
              </button>

              <div className="component-detail-container__detail-inner">
                <h1
                  className="bosch-text-headline relative pt-6 pr-4 text-white"
                  dangerouslySetInnerHTML={{
                    __html: serviceQuery.data?.headline,
                  }}
                />
                <div
                  className="component-detail-container__detail-body hyphens mt-4 overflow-scroll break-words pr-6"
                  ref={scrollContainerRef}
                  dangerouslySetInnerHTML={{ __html: serviceQuery.data?.description }}
                /> 
                <ScrollIndicatorIcon className={cx("absolute bottom-4 right-3 h-8 w-8 fill-white/50 transition-opacity duration-200", isScrollable ? 'opacity-1' : 'opacity-0')} />
              </div>
            </animated.div>
          )
      )}
    </ComponentDetailContainer>
  );
};
