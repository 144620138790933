import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { defaults } from '../static-data/defaults';
import { useUserDefaultsStore } from '../stores/userDefaults/useUserDefaults.store';
import { ReactComponent as ScrollIndicatorIcon } from '../assets/icons/scroll-indicator.svg';
import { isNative } from "../utils/platform";

export type SubpageGridContainerProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  mostInnerClassName?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  headline?: string;
  description?: string | ReactNode;
};

const Section = styled.section((props: any) => ({
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: props.backgroundPosition,
  backgroundColor: '#fff',
}));

export const SubpageGridContainer = forwardRef(
  (
    {
      children,
      backgroundPosition,
      className,
      backgroundImage,
      innerClassName,
      mostInnerClassName,
      headline,
      description,
      ...props
    }: SubpageGridContainerProps,
    ref
  ) => {
    const dataDirectoryBasePath = useUserDefaultsStore((state) => state.dataDirectoryBasePath);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | undefined>(undefined);
    const [isScrollable, setIsScrollable] = useState(false);
    const [scrollIndicatorPosition, setScrollIndicatorPosition] = useState({ left: 0, top: 0 });
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (backgroundImage) {
        if (isNative) {
          setBackgroundImageUrl(
            `${dataDirectoryBasePath}${backgroundImage}`
          );
        } else {
          setBackgroundImageUrl(backgroundImage);
        }
      } else {
        setBackgroundImageUrl(defaults.backgroundImage);
      }
    }, [backgroundImage, dataDirectoryBasePath]);

    useEffect(() => {
      if (scrollContainerRef?.current) {
        const rect = scrollContainerRef?.current.getBoundingClientRect();
        setScrollIndicatorPosition({ left: rect.left + rect.width, top: rect.bottom - 70 });
        setIsScrollable(scrollContainerRef?.current?.scrollHeight > scrollContainerRef?.current?.clientHeight);
      }
    }, [scrollContainerRef.current]);

    return (
      <IonPage>
        <IonContent fullscreen forceOverscroll={false}>
          <Section
            backgroundImage={backgroundImageUrl}
            backgroundPosition={backgroundPosition || 'center'}
            className={cx('absolute inset-0', !isNative && 'mx-auto max-h-full', className)}
            ref={ref}
          >
            <div className="text-bg-gradient" />
            <div className={cx('absolute left-8 right-8 bottom-8 top-0', innerClassName)}>
              <div className={cx('subpage-grid-container', mostInnerClassName)}>
                {headline && (
                  <div className="subpage-grid-container__headline">
                    <h1
                      className="bosch-text-headline hyphens break-words"
                      dangerouslySetInnerHTML={{
                        __html: headline,
                      }}
                    />
                  </div>
                )}
                <div className="subpage-grid-container__body overflow-x-hidden overflow-y-scroll" ref={scrollContainerRef}>
                  {typeof description === 'string' && (
                    <div
                      className="bosch-text-body hyphens overflow-scroll break-words"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  )}
                  {typeof description === 'object' && description}
                </div>
                {isScrollable && (
                  <ScrollIndicatorIcon
                    className="fixed h-8 w-8 fill-bosch-dark-gray-b25"
                    style={{
                      left: scrollIndicatorPosition.left,
                      top: scrollIndicatorPosition.top,
                    }}
                  />
                )}
                {children}
              </div>
            </div>
          </Section>
        </IonContent>
      </IonPage>
    );
  }
);
