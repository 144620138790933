import { Link } from 'react-router-dom';
import { ServiceMenuGridContainer } from '../../components/service-menu-grid-container';
import { getLinkForNextLevel } from './next-level-link.helper';
import { isDebugMode } from '../../utils/debug.utils';
import { usePageData } from '../../hooks/usePageData/usePageData.hook';
import { useQuery } from '@tanstack/react-query';
import { Asset, Service } from '../../stores/coreDataV2/coreData-v2-types';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';
import { getLocalFilePath, isNative } from '../../utils/platform';
import {routes} from "../../utils/routes";

export const ServicesOverviewLevelTwo = () => {
  const { getPageData, nodeRef } = usePageData();
  const { language } = useUserDefaultsStore();
  const { coreData } = useCoreDataV2Store();
  const topicQuery = useQuery(['SPACECAR', coreData?.id, language.current, nodeRef.current?.split('.')[0]], () => getPageData(nodeRef.current?.split('.')[0] as string), {enabled: !!nodeRef.current})
  const serviceQuery = useQuery(['SPACECAR', coreData?.id, language.current, ...(nodeRef.current?.split('.') || [])], () => getPageData(nodeRef.current as string), {enabled: !!nodeRef.current})

  const backgroundImageUrlRaw = topicQuery.data?.backgrounds.find((item: Asset) => item.fileName.includes('_overview_'))?.url;
  const backgroundImageUrl = isNative ? getLocalFilePath(backgroundImageUrlRaw) : backgroundImageUrlRaw;

  return (
    <ServiceMenuGridContainer numberOfItems={serviceQuery?.data?.services?.length} headline={serviceQuery?.data?.headline} backgroundImage={backgroundImageUrl}>
      {serviceQuery?.data?.services?.map((service: Service) => (
        <Link
          to={{
            pathname: getLinkForNextLevel(service, routes.services__overview_nested),
            search: `?node=${nodeRef.current}.services[id="${service.id}"]`,
          }}
          key={service.id}
          className="relative flex items-end bg-bosch-light-blue bg-opacity-80 p-6 text-xl font-bold text-white backdrop-blur-md"
          dangerouslySetInnerHTML={{
            __html: isDebugMode()
              ? `${service?.label} <span class='bg-red-700 absolute right-0 top-0'>(${service?.label})</span>`
              : `${service?.label}`,
          }}
        />
      ))}
    </ServiceMenuGridContainer>
  );
};
