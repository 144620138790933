export const translations = {
  de: {
    translation: {
      rotateDeviceHint: 'Um das bestmögliche Ergebnis zu erzielen, drehen Sie bitte Ihr Gerät.',
      offlineHint: 'Bitte überprüfen Sie Ihre Internetverbindung. Du scheinst offline zu sein.',
      lookingForNewData__hint: 'Nach Updates suchen...',
      sendingLogData__hint: 'Sende Diagnosedaten...',
      components__button: 'Komponenten',
      overview__button: 'Übersicht',
      chooseTopic__hint: 'Bitte wählen Sie ein Thema aus',
      slider__backButton: 'Zurück',
      slider__nextButton: 'Weiter',
      slider__video_loading: 'Video wird geladen...',
      chooseComponent__hint: 'Bitte tippen Sie auf einen Komponentennamen, um weitere Details zu sehen',
      updateAlert: {
        updateAvailable__title: 'Update verfügbar',
        updateAvailable__message: 'Es sind neuere Daten verfügbar. Möchten Sie diese jetzt herunterladen?',
        updateAvailable__buttonOk: 'Update herunterladen',
        updateAvailable__buttonCancel: 'Vielleicht später',
        noUpdateAvailable__title: 'Kein Update verfügbar',
        noupdateAvailable__message: 'Alle Daten sind auf dem aktuellen Stand.',
        noUpdateAvailable__buttonOk: 'Schließen',

        updateAvailableFirstLaunch__title: 'Willkommen bei der Bosch Space Car App✨.',
        updateAvailableFirstLaunch__message:
          'Bosch Space Car wurde zum ersten Mal gestartet. Die Basisdaten sind bereits geladen. <br><br>Um jedoch die App vollumfänglich nutzen zu können, laden Sie bitte die neuesten Daten herunter.',
        updateAvailableFirstLaunch__buttonOk: 'Daten herunterladen',
        updateAvailableFirstLaunch__buttonCancel: 'Später vielleicht',
      },
      deleteAlert: {
        deleteContent__header: 'Inhalte löschen',
        deleteContent__message: 'Möchten Sie alle Inhalte wirklich löschen?',
        deleteContent__buttonOk: 'Alle Inhalte löschen',
        deleteContent__buttonCancel: 'Abbrechen',
        deleteContent__progress: 'Inhalte werden gelöscht...',
        deleteContentSuccess__header: 'Inhalte gelöschen',
        deleteContentSuccess__message: 'Alle Inhalte sind von diesem Gerät gelöscht.',
        deleteContentSuccess__buttonOk: 'Schließen',
        deleteContentFailure__header: 'Inhalte löschen fehlgeschlagen',
        deleteContentFailure__message: 'Beim Löschen der Inhalte ist ein Problem aufgetreten.',
        deleteContentFailure__buttonOk: 'Schließen',
        deleteLogs__header: 'Logeinträge löschen',
        deleteLogs__message: 'Möchten Sie alle Logeinträge wirklich löschen?',
        deleteLogs__buttonOk: 'Alle Logeinträge löschen',
        deleteLogs__buttonCancel: 'Abbrechen',
      },
      checkAssets: {
        checkAssets__button: 'Mediadaten übprüfen',
        checkAssets__messageProgress: 'Mediadaten übprüfen',
        checkAssets__messageOk: 'Keine fehlenden Daten gefunden. Alle Daten sind offline verfügbar.',
        checkAssets__messageMissing: '{{missing}} Mediadaten fehlen (~{{size}}). Bitte laden Sie die Daten erneut herunter.',
      },
      deleteAssets: {
        deleteAssets__button: 'Alle Mediadaten löschen',
        deleteAssets_alert__header: 'Mediadaten löschen?',
        deleteAssets_alert__buttonOk: 'Alle Mediadaten löschen!',
        deleteAssets_alert__buttonCancel: 'Abbrechen',
        deleteAssets_alert__message: 'Sind Sie sicher, dass Sie alle Mediadaten löschen möchten?',
        deleteAssets_alert__messageSuccess: 'Alle Mediadaten wurden gelöscht',
      },
      missingAssets: {
        missingAssets__header: 'Fehlende Dateien',
        missingAssets__body: 'Alle fehlenden Dateien herunterladen',
        missingAssets__buttonDownload: 'Alle fehlenden Dateien herunterladen',
      },
      diagnosticAlert: {
        sendingDiagnosticDataFailure__header: 'Senden fehlgeschlagen',
        sendingDiagnosticDataFailure__message: 'Diagnosedaten senden fehlgeschlagen.',
        sendingDiagnosticDataFailure__buttonOk: 'Okay',
        sendingDiagnosticDataSuccess__header: 'Senden erfolgreich',
        sendingDiagnosticDataSuccess__message: 'Vielen Dank. Wir haben die Diagnosedaten erhalten.',
        sendingDiagnosticDataSuccess__buttonOk: 'Okay',
      },
      restartAlert: {
        restartToTakeEffect__header: 'Neustart erforderlich',
        restartToTakeEffect__message:
          'Bitte starten Sie die App neu, damit die Änderungen wirksam werden. Bitte stellen Sie zudem sicher, dass die App nicht im Hintergrund aktiv bleibt.',
        restartToTakeEffect__buttonOk: 'Okay',
      },
      noInternetAlert: {
        noInternetConnectionAvailable__title: 'Keine Intenetverbindung',
        noInternetConnectionAvailable__message: 'Aktuell besteht keine Verbindung zum Internet.',
        noInternetConnectionAvailable__buttonOk: '',
      },
      languages: {
        de: 'Deutsch',
        en: 'English',
        jp: '日本語',
        cn: '中文',
      },
      settings: {
        headline: 'Einstellungen',
        dataManagement: {
          headline: '🗄 Datenverwaltung',
          description: 'Verwalten Sie alle Daten, die Sie in der App verwenden.',
          content__headline: 'Inhalte',
          dangerArea__headline: 'Gefahrenbereich',
          dangerArea__body:
            'Alle Inhalte der App können ganz einfach gelöscht werden, sollten einmal ungewünschte Daten nicht aktualisiert werden.',
          dangerAreaDeleteAllContent__button: 'Alle Inhalte löschen',
          installedVersion__label: 'Installierte Version',
          latestVersion__label: 'Neuste Version',
          noVersionInstalled__label: 'Nicht installiert',
          alreadyInstalled__label: 'Bereits installiert',
          checkForUpdates__label: 'Nach Updates suchen',
          checkForUpdatesLoading__label: 'Suche nach Updates...',
          gatheringDownloadSize__label: 'Download-Größe berechnen...',
          downloadUpdate__label: 'Updates herunterladen',
          downloadUpdateDone__message: 'Updates wurden heruntergeladen.',
          downloadingFiles__label: 'Dateien werden heruntergeladen ({{received}} von {{total}})',
          downloadingFiles_fileOfFiles__label: 'Datei {{filesCompleted}} von {{total}}',
          downloadingFiles_skippedExisting: 'Übersprungene Dateien: ',
          downloadingFiles_currentFile: 'Aktuelle Datei: ',
          updateAvailable__label: 'Update verfügbar',
          updateAvailableContent__label: 'Neue Inhalte verfügbar',
          updateAvailableAssets__label: 'Neue Media-Dateien verfügbar',
          updateNotAvailableAssets__label: 'Keine neuen Media-Dateien verfügbar.',
          updateAvailableAssetsFiles__label: 'Dateien,',
        },
        debug: {
          headline: '🐛 Debug',
          description:
            'Debugging-Optionen und Informationen. Bitte seien Sie vorsichtig. Fahren Sie nur fort, wenn Sie wissen, was Sie tun.',
          contentToggle__headline: 'Inhalt Ein-/Ausblenden',
          logEntries__headline: 'Protokollierung',
          logEntries__toggle: 'Protokollierung aktivieren/deaktivieren',
          logEntries_send__button: 'Diagnosedaten senden',
          logEntries_delete__button: 'Diagnosedaten löschen',
        },
      },
      generic: {
        close__button: 'Schließen',
        changingLanguage__label: 'Sprache wird geändert...',
      },
    },
  },
  en: {
    translation: {
      rotateDeviceHint: 'To get the best experience possible, please rotate your device.',
      offlineHint: 'Please check your internet connection. You seem to be offline.',
      lookingForNewData__hint: 'Looking for updates...',
      sendingLogData__hint: 'Send diagnostic data...',
      components__button: 'Components',
      overview__button: 'Overview',
      chooseTopic__hint: 'Please choose a topic to move on',
      chooseComponent__hint: 'Please tap on a component name to see more details',
      updateAlert: {
        updateAvailable__title: 'Update available',
        updateAvailable__message: 'There is newer data available. Do you want to download it now?',
        updateAvailable__buttonOk: 'Download update',
        updateAvailable__buttonCancel: 'Maybe later',
        noUpdateAvailable__title: 'No update available',
        noupdateAvailable__message: 'All data is up to date',
        noUpdateAvailable__buttonOk: 'Close',
        updateAvailableFirstLaunch__title: 'Welcome to the Bosch Space Car App ✨',
        updateAvailableFirstLaunch__message:
          "This is the first time you launch the app. <br/><br/>There's new data available. Do you want to download it now? You can also do this later.",
        updateAvailableFirstLaunch__buttonOk: 'Go to downloads',
        updateAvailableFirstLaunch__buttonCancel: 'No thanks, maybe later',
      },
      slider__backButton: 'Back',
      slider__nextButton: 'Next',
      slider__video_loading: 'Loading video...',
      settings: {
        headline: 'Settings',
        dataManagement: {
          headline: '🗄 Data Management',
          description: 'Manage all the data you use in the app.',
          content__headline: 'Content',
          dangerArea__headline: 'Danger area',
          dangerArea__body: 'All contents of the app can be easily deleted, should once unwanted data not be updated.',
          dangerAreaDeleteAllContent__button: 'Delete all content',
          dangerAreaDeleteAllAssets__button: 'Delete all assets',
          installedVersion__label: 'Installed version',
          latestVersion__label: 'Latest version',
          noVersionInstalled__label: 'Not installed',
          alreadyInstalled__label: 'Already installed',
          checkForUpdates__label: 'Check for updates',
          checkForUpdatesLoading__label: 'Searching for updates...',
          gatheringDownloadSize__label: 'Calculating download size...',
          downloadUpdate__label: 'Download updates',
          downloadUpdateDone__message: 'Updates have been downloaded.',
          downloadingFiles__label: 'Files being downloaded ({{received}} of {{total}})',
          downloadingFiles_fileOfFiles__label: 'File {{filesCompleted}} of {{total}}',
          downloadingFiles_skippedExisting: 'Skipped Files:',
          downloadingFiles_currentFile: 'Current file: ',
          updateAvailable__label: 'Update available',
          updateAvailableContent__label: 'New content available',
          updateAvailableAssets__label: 'New assets available',
          updateNotAvailableAssets__label: 'No new assets available.',
          updateAvailableAssetsFiles__label: 'files,',
        },
        debug: {
          headline: '🐛 Debug',
          description: 'Debugging options and information. Please be careful. Only proceed if you know what you are doing.',
          contentToggle__headline: 'Toggle Content',
          logEntries__headline: 'Diagnostics',
          logEntries__toggle: 'Enable/disable logging',
          logEntries_send__button: 'Send diagnostic data',
          logEntries_delete__button: 'Delete diagnostic data',
        },
      },
      deleteAlert: {
        deleteContent__header: 'Delete content',
        deleteContent__message: 'Do you really want to delete all content?',
        deleteContent__buttonOk: 'Delete all content',
        deleteContent__buttonCancel: 'Cancel',
        deleteContent__progress: 'Content will be deleted...',
        deleteContentSuccess__header: 'Delete content',
        deleteContentSuccess__message: 'All content is deleted from this device.',
        deleteContentSuccess__buttonOk: 'Close',
        deleteContentFailure__header: 'Delete content failed',
        deleteContentFailure__message: 'A problem occurred while deleting the content.',
        deleteContentFailure__buttonOk: 'Close',
        deleteLogs__header: 'Delete log entries',
        deleteLogs__message: 'Do you really want to delete all log entries?',
        deleteLogs__buttonOk: 'Delete all log entries',
        deleteLogs__buttonCancel: 'Cancel',
      },
      checkAssets: {
        checkAssets__button: 'Check media data',
        checkAssets__messageProgress: 'Check media data',
        checkAssets__messageOk: 'No missing data found. All data is available offline',
        checkAssets__messageMissing: '{{missing}} Media data is missing (~{size}}). Please download the data again.',
      },
      deleteAssets: {
        deleteAssets__button: 'Delete all media data',
        deleteAssets_alert__header: 'Delete media data?',
        deleteAssets_alert__buttonOk: 'Delete all media data!',
        deleteAssets_alert__buttonCancel: 'Cancel',
        deleteAssets_alert__message: 'Are you sure you want to delete all media data?',
        deleteAssets_alert__messageSuccess: 'All media data has been deleted',
      },
      missingAssets: {
        missingAssets__header: 'Missing files',
        missingAssets__body: 'Download all missing files',
        missingAssets__buttonDownload: 'Download all missing files',
      },
      diagnosticAlert: {
        sendingDiagnosticDataFailure__header: 'Send failed',
        sendingDiagnosticDataFailure__message: 'Send diagnostic data failed',
        sendingDiagnosticDataFailure__buttonOk: 'Okay',
        sendingDiagnosticDataSuccess__header: 'Sending successful',
        sendingDiagnosticDataSuccess__message: 'Thank you very much. We have received the diagnostic data.',
        sendingDiagnosticDataSuccess__buttonOk: 'Okay',
      },
      restartAlert: {
        restartToTakeEffect__header: 'Restart required',
        restartToTakeEffect__message:
          'Please restart the app for the changes to take effect. Also, please make sure that the app does not remain active in the background.',
        restartToTakeEffect__buttonOk: 'Okay',
      },
      noInternetAlert: {
        noInternetConnectionAvailable__title: 'No internet connection',
        noInternetConnectionAvailable__message: "Currently there's no internet connection available.",
        noInternetConnectionAvailable__buttonOk: 'Okay',
      },
      languages: {
        de: 'Deutsch',
        en: 'English',
        jp: '日本語',
        cn: '中文',
      },
      generic: {
        close__button: 'Close',
        changingLanguage__label: 'Language will be changed...',
      },
    },
  },
  jp: {
    translation: {
      rotateDeviceHint: '最高の体験をするために、デバイスを回転させてください。',
      offlineHint: 'インターネット接続環境をご確認ください。オフラインになっているようです。',
      lookingForNewData__hint: '更新情報を探しています...',
      sendingLogData__hint: '診断データを送信する...',
      components__button: 'コンポーネント',
      overview__button: '概要',
      chooseTopic__hint: '次に進むためのトピックを選択してください',
      chooseComponent__hint: 'コンポーネント名をタップすると詳細が表示されます。',
      updateAlert: {
        updateAvailable__title: 'アップデートが可能です',
        updateAvailable__message: 'There is newer data available. 今すぐダウンロードしますか』。',
        updateAvailable__buttonOk: 'アップデートをダウンロードする',
        updateAvailable__buttonCancel: '後でいいや',
        noUpdateAvailable__title: 'アップデートは利用できません',
        noupdateAvailable__message: 'すべてのデータは最新です',
        noUpdateAvailable__buttonOk: '閉じる',

        updateAvailableFirstLaunch__title: 'ようこそ、Bosch Space Car App へ✨。',
        updateAvailableFirstLaunch__message:
          'ボッシュ・スペースカーを初号機として発売。基本データはすでに読み込まれています。ただし、アプリを十分に活用するためには、最新のデータをダウンロードしてください。',
        updateAvailableFirstLaunch__buttonOk: 'データをダウンロードする',
        updateAvailableFirstLaunch__buttonCancel: 'たぶん後で',
      },
      slider__backButton: '戻る',
      slider__nextButton: '次のページ',
      slider__video_loading: 'ビデオを読み込んでいます...',
      settings: {
        headline: '設定',
        dataManagement: {
          headline: '🗄 データ管理',
          description: '使用するすべてのデータをアプリで管理します。',
          content__headline: 'コンテンツ',
          dangerArea__headline: '危険エリア',
          dangerArea__body: '不要なデータが更新されない場合、アプリのすべてのコンテンツを簡単に削除することができます。',
          dangerAreaDeleteAllContent__button: 'すべてのコンテンツを削除する',
          dangerAreaDeleteAllAssets__button: 'すべてのメディアデータを削除する',
          installedVersion__label: 'インストール済み',
          latestVersion__label: '最新バージョン',
          noVersionInstalled__label: 'インストールされていません',
          alreadyInstalled__label: 'すでにインストールされています',
          checkForUpdates__label: 'アップデートを確認する',
          checkForUpdatesLoading__label: 'アップデートを探す...',
          gatheringDownloadSize__label: 'ダウンロードサイズの計算...',
          downloadUpdate__label: 'アップデートをダウンロードする',
          downloadUpdateDone__message: 'アップデートがダウンロードされました。',
          downloadingFiles__label: 'ダウンロードされたファイル（{{total}}から{{received}}）。',
          downloadingFiles_fileOfFiles__label: 'ファイル {{filesCompleted}} から {{total}} をダウンロード。',
          downloadingFiles_skippedExisting: 'スキップされたファイル。',
          downloadingFiles_currentFile: '現在のファイル ',
          updateAvailable__label: 'アップデート可能',
          updateAvailableContent__label: '新コンテンツを公開',
          updateAvailableAssets__label: '新たな資産を獲得',
          updateNotAvailableAssets__label: '新しい資産はありません。',
          updateAvailableAssetsFiles__label: 'のファイルです。',
        },
        debug: {
          headline: '🐛 デバッグ',
          description: 'デバッグのオプションと情報。注意してください。何をやっているか分かっている場合のみ進めてください。',
          contentToggle__headline: 'コンテンツの表示/非表示',
          logEntries__headline: 'ロギング',
          logEntries__toggle: 'ロギングを有効化/無効化。',
          logEntries_send__button: '診断データを送信する',
          logEntries_delete__button: '「診断データを削除する」。',
        },
      },
      deleteAlert: {
        deleteContent__header: 'コンテンツを削除する',
        deleteContent__message: '本当にコンテンツを削除しますか？',
        deleteContent__buttonOk: 'すべてのコンテンツを削除します。',
        deleteContent__buttonCancel: 'キャンセル。',
        deleteAssets__header: 'アセットを削除する。',
        deleteAssets__message: '本当に全てのアセットを削除しますか？',
        deleteAssets__buttonOk: '「すべてのアセットを削除します」。',
        deleteAssets__buttonCancel: 'キャンセル。',
        deleteLogs__header: 'ログエントリーを削除する',
        deleteLogs__message: '本当にすべてのログエントリを削除しますか?',
        deleteLogs__buttonOk: 'すべてのログエントリを削除する',
        deleteLogs__buttonCancel: 'キャンセル',
      },
      checkAssets: {
        checkAssets__button: '「メディアデータを確認する」。',
        checkAssets__messageProgress: 'メディアデータのチェック',
        checkAssets__messageOk: '不足しているデータは見つかりませんでした。すべてのデータはオフラインで利用可能です。',
        checkAssets__messageMissing: '{{missing}}. メディアデータがありません(~{size}})。もう一度データをダウンロードしてください。',
      },
      deleteAssets: {
        deleteAssets__button: 'すべてのメディアデータを削除する',
        deleteAssets_alert__header: 'メディアデータを削除しますか',
        deleteAssets_alert__buttonOk: 'すべてのメディアデータを削除！',
        deleteAssets_alert__buttonCancel: 'キャンセル',
        deleteAssets_alert__message: '「本当にすべてのメディアデータを削除しますか？',
        deleteAssets_alert__messageSuccess: 'すべてのメディアデータが削除されました',
      },
      missingAssets: {
        missingAssets__header: '不足しているファイル',
        missingAssets__body: '不足しているファイルをすべてダウンロードする',
        missingAssets__buttonDownload: '不足しているファイルをすべてダウンロードする。',
      },
      diagnosticAlert: {
        sendingDiagnosticDataFailure__header: '送信失敗',
        sendingDiagnosticDataFailure__message: '診断データの送信に失敗しました',
        sendingDiagnosticDataFailure__buttonOk: 'オーケー',
        sendingDiagnosticDataSuccess__header: '送信成功',
        sendingDiagnosticDataSuccess__message: 'ありがとうございました。診断データを受け取りました。',
        sendingDiagnosticDataSuccess__buttonOk: 'オーケー',
      },
      restartAlert: {
        restartToTakeEffect__header: '再起動が必要',
        restartToTakeEffect__message:
          '変更を反映させるには、アプリを再起動してください。また、アプリがバックグラウンドでアクティブな状態にならないようにご注意ください。',
        restartToTakeEffect__buttonOk: 'オーケー',
      },
      noInternetAlert: {
        noInternetConnectionAvailable__title: 'インターネットに接続できない',
        noInternetConnectionAvailable__message: '現在、インターネットには接続されていません。',
        noInternetConnectionAvailable__buttonOk: 'オーケー',
      },
      languages: {
        de: 'Deutsch',
        en: 'English',
        jp: '日本語',
        cn: '中文',
      },
      generic: {
        close__button: '閉じる',
        changingLanguage__label: '言語の変更...',
      },
    },
  },
  cn: {
    translation: {
      rotateDeviceHint: '为了获得尽可能好的体验，请旋转你的设备。',
      offlineHint: '请检查您的网络连接。你似乎已经下线了。',
      lookingForNewData__hint: '寻找更新...',
      sendingLogData__hint: '发送诊断数据...',
      components__button: '组件',
      overview__button: '概述',
      chooseTopic__hint: '请选择一个主题，继续前进',
      chooseComponent__hint: '请点击组件名称以查看更多细节',
      updateAlert: {
        updateAvailable__title: '更新可用。',
        updateAvailable__message: '有更新的数据可用。你想现在下载吗？。',
        updateAvailable__buttonOk: '下载更新',
        updateAvailable__buttonCancel: '也许以后',
        noUpdateAvailable__title: '没有更新。',
        noupdateAvailable__message: '所有数据都是最新的。',
        noUpdateAvailable__buttonOk: '关闭',
        updateAvailableFirstLaunch__title: '欢迎来到 Bosch Space Car App ✨',
        updateAvailableFirstLaunch__message:
          '博世太空车首次推出。基本数据已经加载完毕。然而，为了能够充分使用该应用程序，请下载最新的数据。',
        updateAvailableFirstLaunch__buttonOk: '下载数据',
        updateAvailableFirstLaunch__buttonCancel: '也许以后',
      },
      slider__backButton: '上一页',
      slider__nextButton: '下一页',
      slider__video_loading: '视频加载中...',
      settings: {
        headline: '设置',
        dataManagement: {
          headline: '🗄 数据管理',
          description: '管理你在应用程序中使用的所有数据。',
          content__headline: '内容。',
          dangerArea__headline: '危险区域。',
          dangerArea__body: '如果不需要的数据不被更新，应用程序的所有内容都可以被轻易删除。',
          dangerAreaDeleteAllContent__button: '删除所有内容。',
          dangerAreaDeleteAllAssets__button: '删除所有媒体数据。',
          installVersion__label: '已安装。',
          latestVersion__label: '最新版本。',
          noVersionInstalled__label: '未安装。',
          alreadyInstalled__label: '已经安装。',
          checkForUpdates__label: '检查更新。',
          checkForUpdatesLoading__label: '搜索更新...',
          gatheringDownloadSize__label: '计算下载大小...',
          downloadUpdate__label: '下载更新。',
          downloadingFiles__label: '文件被下载（{{received}}来自{{total}}）。',
          downloadUpdateDone__message: '已经下载了更新。',
          downloadingFiles_fileOfFiles__label: '文件{{filesCompleted}}来自{{total}}。',
          downloadingFiles_skippedExisting: '跳过的文件。',
          downloadingFiles_currentFile: '目前的文件。 ',
          updateAvailable__label: '可提供最新信息',
          updateAvailableContent__label: '现有的新内容',
          updateAvailableAssets__label: '可用的新资产',
          updateNotAvailableAssets__label: '没有新的资产可用。',
          updateAvailableAssetsFiles__label: '文件。',
        },
        debug: {
          headline: '🐛 调试',
          description: '调试选项和信息。请小心。只有在你知道你在做什么的情况下才能继续。',
          contentToggle__headline: '显示/隐藏内容。',
          logEntries__headline: '记录。',
          logEntries__toggle: '激活/关闭日志记录。',
          logEntries_send__button: '发送诊断数据。',
          logEntries_delete__button: '删除诊断数据。',
        },
      },
      deleteAlert: {
        deleteContent__header: '删除内容。',
        deleteContent__message: '你真的想删除所有内容吗？。',
        deleteContent__buttonOk: '删除所有内容。',
        deleteContent__buttonCancel: '取消。',
        deleteContent__progress: '内容将被删除...。',
        deleteContentSuccess__header: '删除内容。',
        deleteContentSuccess__message: '所有的内容都从这个设备上删除了。',
        deleteContentSuccess__buttonOk: '关闭',
        deleteContentFailure__header: '删除内容失败。',
        deleteContentFailure__message: '在删除内容时发生了一个问题。',
        deleteContentFailure__buttonOk: '关闭',
        deleteLogs__header: '删除日志条目。',
        deleteLogs__message: '你真的想删除所有日志条目吗？。',
        deleteLogs__buttonOk: '删除所有日志条目。',
        deleteLogs__buttonCancel: '取消。',
      },
      checkAssets: {
        checkAssets__button: '检查媒体数据。',
        checkAssets__messageProgress: '检查媒体数据。',
        checkAssets__messageOk: '没有发现丢失的数据。所有数据都可以离线使用。',
        checkAssets__messageMissing: '{{missing}}. 媒体数据丢失（~{size}}）。请重新下载数据。',
      },
      deleteAssets: {
        deleteAssets__button: '删除所有媒体数据。',
        deleteAssets_alert__header: '删除媒体数据？',
        deleteAssets_alert__buttonOk: '删除所有媒体数据！。',
        deleteAssets_alert__buttonCancel: '取消。',
        deleteAssets_alert__message: '你确定要删除所有媒体数据吗？',
        deleteAssets_alert__messageSuccess: '所有媒体数据已被删除',
      },
      missingAssets: {
        missingAssets__header: '缺少的文件',
        missingAssets__body: '下载所有丢失的文件',
        missingAssets__buttonDownload: '下载所有丢失的文件。',
      },
      diagnosticAlert: {
        sendingDiagnosticDataFailure__header: '发送失败',
        sendingDiagnosticDataFailure__message: '发送诊断数据失败',
        sendingDiagnosticDataFailure__buttonOk: '好的',
        sendingDiagnosticDataSuccess__header: '成功发送',
        sendingDiagnosticDataSuccess__message: '非常感谢你。我们已经收到了诊断数据。',
        sendingDiagnosticDataSuccess__buttonOk: '好的',
      },
      restartAlert: {
        restartToTakeEffect__header: '需要重新启动',
        restartToTakeEffect__message: '请重新启动应用程序以使更改生效。此外，请确保该应用程序不会在后台保持活跃。',
        restartToTakeEffect__buttonOk: '好的',
      },
      noInternetAlert: {
        noInternetConnectionAvailable__title: '没有互联网连接',
        noInternetConnectionAvailable__message: '目前还没有连接到互联网。',
        noInternetConnectionAvailable__buttonOk: '好的',
      },
      languages: {
        de: 'Deutsch',
        en: 'English',
        jp: '日本語',
        cn: '中文',
      },
      generic: {
        close__button: '关闭',
        changingLanguage__label: '语言被改变...',
      },
    },
  },
};
