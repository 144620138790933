import { IonContent, IonPage } from '@ionic/react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import { forwardRef, ReactNode } from 'react';
import { isNative } from '../utils/platform';

const Section = styled.section((props: any) => ({
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: props.backgroundPosition,
  backgroundColor: 'transparent',
}));

export type HomeGridContainerProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  mostInnerClassName?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
};

export const HomeGridContainer = forwardRef(
  (
    { children, backgroundImage, backgroundPosition, className, innerClassName, mostInnerClassName, ...props }: HomeGridContainerProps,
    ref
  ) => {
    return (
      <IonPage>
        <IonContent fullscreen forceOverscroll={false}>
          <Section
            backgroundImage={backgroundImage}
            backgroundPosition={backgroundPosition || 'center'}
            className={cx('absolute inset-0', !isNative && 'mx-auto max-h-full', className)}
            ref={ref}
          >
            <div className={cx('absolute left-8 right-8 bottom-8 top-0', innerClassName)}>
              <div className={cx('flex flex-col h-full w-full', mostInnerClassName)}>{children}</div>
            </div>
          </Section>
        </IonContent>
      </IonPage>
    );
  }
);
