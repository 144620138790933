import { useRef } from 'react';
import { FlagComponent } from '../../components/flag/flag';
import { useTransition } from 'react-spring';
import { ComponentTopviewContainer } from '../../components/component-topview-container';
import qs from 'qs';
import { useFlags } from '../../hooks/useFlags/useFlags.hook';
import { usePageData } from '../../hooks/usePageData/usePageData.hook';
import { useQuery } from '@tanstack/react-query';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { Asset } from '../../stores/coreDataV2/coreData-v2-types';
import { staggeredTransitionConfig } from '../../animations/animation';
import { isNative } from '../../utils/platform';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';

export const ComponentsOverview = () => {
  const imageContainerRef = useRef<any | null>(null);
  const { getPageData, nodeRef } = usePageData();
  const { language } = useUserDefaultsStore();
  const { coreData } = useCoreDataV2Store();
  const serviceQuery = useQuery(['SPACECAR', coreData?.id, language.current, ...(nodeRef.current?.split('.') || [])], () => getPageData(nodeRef.current as string), {enabled: !!nodeRef.current})
  
  const { flags } = useFlags(serviceQuery.data?.flags, imageContainerRef);
  const transitions = useTransition(flags, staggeredTransitionConfig);


  const backgroundImageUrl = serviceQuery.data?.backgrounds.find((item: Asset) => item.fileName.includes('_top_'))?.url;
  
  return (
    <ComponentTopviewContainer
      backgroundImage={isNative ? backgroundImageUrl?.replace(`${process.env.REACT_APP_BASE_URL_DATA}/`, '') : backgroundImageUrl}
      backgroundPosition="center"
      headline={serviceQuery.data?.headline}
      ref={imageContainerRef}
    >
      {/*      {isDebugMode() && (
        <>
          <div className="fixed left-0 top-0 z-10 flex gap-[1px] font-semibold">
            <div className="flex bg-red-700 px-2 pt-2 pb-1 text-white">Level: {pageData?.level}</div>
            <div className="flex bg-red-700 px-2 pt-2 pb-1 text-white">
              Flags ({pageData?.attributes?.flagIds?.split(';')?.length}) : [{pageData?.attributes?.flagIds}]
            </div>
          </div>
          <div className="pointer-events-none fixed top-[100px] right-[50px] bottom-[50px] left-[50px] z-10 border-4 border-solid border-[yellow]" />
        </>
      )}*/}

      <div className="absolute inset-0">
        {transitions((styles, flag) => {
          return (
            <FlagComponent
              key={flag?.cmsId}
              styles={styles}
              idx={flag?.idx}
              parentNode={qs.parse(location.search, { ignoreQueryPrefix: true }).node}
              {...flag}
              component={flag.component}
            />
          );
        })}
      </div>
    </ComponentTopviewContainer>
  );
};
