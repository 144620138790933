import { IonContent, IonPage } from '@ionic/react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import { forwardRef, ReactNode } from 'react';
import { useUserDefaultsStore } from '../stores/userDefaults/useUserDefaults.store';
import { isNative } from '../utils/platform';

const Section = styled.section((props: any) => ({
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: props.backgroundPosition,
  backgroundColor: '#fff',
}));

export type ComponentTopviewContainerProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  headline?: string;
};

export const ComponentTopviewContainer = forwardRef(
  (
    {
      children,
      backgroundImage,
      backgroundPosition,
      className,
      innerClassName,
      headline = '',
    }: ComponentTopviewContainerProps,
    ref
  ) => {
    const dataDirectoryBasePath = useUserDefaultsStore((state) => state.dataDirectoryBasePath);

    return (
      <IonPage>
        <IonContent fullscreen forceOverscroll={false}>
          <Section
            className={cx('absolute inset-0', !isNative && 'mx-auto max-h-full', className)}
            backgroundImage={isNative ? `${dataDirectoryBasePath}${backgroundImage}` : backgroundImage}
            backgroundPosition={backgroundPosition || 'center'}
            ref={ref}
          >
            <div className={cx('component-topview-container relative h-full w-full', innerClassName)}>
              <div className="component-topview-container__headline" dangerouslySetInnerHTML={{ __html: headline }} />
              {children}
            </div>
          </Section>
        </IonContent>
      </IonPage>
    );
  }
);
