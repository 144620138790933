import axios from 'axios';
import {Directory, Encoding, Filesystem} from '@capacitor/filesystem';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { FILESYSTEM_PREFIX } from '../../constants/files-directories';
import { useDownloadStatsStore } from '../../stores/downloadStats/useDownloadStats.store';
import PQueue from 'p-queue';
import { useEffect, useRef, useState } from 'react';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';
import { AssetMetaItem, CoreDataV2 } from '../../stores/coreDataV2/coreData-v2-types';

export type DownloadInfo = {
  files: {
    [key: string]: {
      bytesReceived: number;
      completed: boolean;
    };
  };
  total: {
    bytesReceived: number;
    bytesTotal: number;
    filesCompleted: number;
    filesTotal: number;
  };
};
export const useDownloadManager = () => {
  const userDefaultsStore = useUserDefaultsStore();
  const downloadStatsStore = useDownloadStatsStore();
  const { setCoreData } = useCoreDataV2Store();
  const listenerRef = useRef<any>(null);
  const [downloadInfo, setDownloadInfo] = useState<DownloadInfo>({
    files: {},
    total: { bytesReceived: 0, bytesTotal: 0, filesCompleted: 0, filesTotal: 0 },
  });

  useEffect(() => {
    if (!listenerRef.current) {
      listenerRef.current = Filesystem.addListener('progress', (event) => {
        const { bytes, contentLength, url } = event;
        const key = url;

        setDownloadInfo((state) => {
          const completed = bytes === contentLength;
          const newState = {
            ...state,
            files: { ...state.files, [key]: { bytesReceived: bytes, completed } },
          };

          const totalBytesReceived = Object.values(newState.files).reduce((acc, { bytesReceived }) => acc + bytesReceived, 0);
          const filesCompleted = completed ? newState.total.filesCompleted + 1 : newState.total.filesCompleted;

          return {
            ...newState,
            total: { ...state.total, bytesReceived: totalBytesReceived, filesCompleted },
          };
        });
      });
    }
    return () => {
      Filesystem.removeAllListeners();
    };
  }, []);

  const downloadContent = async ([, url]: [string, string], environment: string): Promise<CoreDataV2 | undefined> => {
    const { data } = await axios.get<CoreDataV2>(url);
    const path = url.split('/').pop() as string;

    try {
      await Filesystem.writeFile({
        path: `${FILESYSTEM_PREFIX}${environment}/schemas/${path}`,
        directory: Directory.Documents,
        data: JSON.stringify(data),
        recursive: true,
        encoding: Encoding.UTF8,
      });
    } catch (e) {
      console.log(e);
    }

    return data as CoreDataV2;
  };

  const downloadAssets = async (assets: AssetMetaItem[]): Promise<void> => {
      if (assets.length > 0) {
        const bytesTotal = assets.reduce((acc, { sizeKbs }) => acc + sizeKbs, 0) * 1024;
        const filesTotal = assets.length;
        const dlInfo = {
          files: assets.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.url]: { bytesReceived: 0, completed: false },
            }),
            {}
          ),
          total: { progress: 0, bytesReceived: 0, bytesTotal, filesCompleted: 0, filesTotal },
        };

        setDownloadInfo(dlInfo);
        const downloadQueue = new PQueue({ concurrency: 1 });
        const defaultOptions = {
          directory: Directory.Documents,
          recursive: true,
          progress: true,
          failOnError: false,
        };

        const downloads = assets.map((asset: AssetMetaItem) => {
          return downloadQueue.add<Promise<any>>(() => Filesystem.downloadFile({
            ...defaultOptions,
            path: asset.url.replace(process.env.REACT_APP_BASE_URL_DATA as string, '').replace(' ', '%20'),
            url: asset.url.replace(' ', '%20'),
          })
        )});

        try {
          await Promise.allSettled(downloads);
        } catch (e) {
          console.error(e);
        }
      }

      Object.entries(userDefaultsStore.availableData).forEach(([language, { remote }]) => {
        userDefaultsStore.setAvailableData({ language, local: remote?.split('/').pop() as string });
      });

      const paths = userDefaultsStore.getCoreDataPathsForLanguage(userDefaultsStore.language.current);
      if (paths?.local) {
        try {
          const { data } = await Filesystem.readFile({
            directory: Directory.Documents,
            path: `${FILESYSTEM_PREFIX}${userDefaultsStore.environment}/schemas/${paths.local}`,
            encoding: Encoding.UTF8,
          })
         
          setCoreData(JSON.parse(data as string));
          downloadStatsStore.updateCurrentState('IDLE');
        } catch (error) {
          downloadStatsStore.updateCurrentState('IDLE');
        }
      }
  };

  return {
    downloadContent,
    downloadAssets,
    downloadInfo,
  };
};
