import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { BaseGridContainer } from '../base-grid-container';
import { routes } from '../../utils/routes';
export const SettingsPage = () => {
  const { t } = useTranslation();
  return (
    <BaseGridContainer headline={t('settings.headline')}>
      <div className='row-span-8 col-span-12 grid grid-cols-2 gap-6'>
        <IonCard routerLink={routes.settings__data} className='m-0 w-full'>
          <IonCardHeader>
            <IonCardTitle className='text-xl'>
              {t('settings.dataManagement.headline')}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {t('settings.dataManagement.description')}
          </IonCardContent>
        </IonCard>
        <IonCard routerLink={routes.settings__debug} className='m-0'>
          <IonCardHeader>
            <IonCardTitle className='text-xl'>
              {t('settings.debug.headline')}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>{t('settings.debug.description')}</IonCardContent>
        </IonCard>
      </div>
    </BaseGridContainer>
  );
};
