import { forwardRef, ReactNode, useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import { replaceBrWithSpace } from '../utils/string.utils';
import { defaults } from '../static-data/defaults';
import { useUserDefaultsStore } from '../stores/userDefaults/useUserDefaults.store';
import { isNative } from "../utils/platform";

export type ServiceMenuGridContainerProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  mostInnerClassName?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  numberOfItems?: number;
  headline?: string;
};

const Section = styled.section((props: any) => ({
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: props.backgroundPosition,
  backgroundColor: '#fff',
}));

export const ServiceMenuGridContainer = forwardRef(
  (
    {
      children,
      backgroundImage,
      backgroundPosition,
      className,
      innerClassName,
      mostInnerClassName,
      numberOfItems = 1,
      headline,
      ...props
    }: ServiceMenuGridContainerProps,
    ref
  ) => {
    const dataDirectoryBasePath = useUserDefaultsStore((state) => state.dataDirectoryBasePath);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
      if (backgroundImage) {
        setBackgroundImageUrl(isNative ? `${dataDirectoryBasePath}${backgroundImage}` : backgroundImage);
      } else {
        setBackgroundImageUrl(defaults.backgroundImage);
      }
    }, [backgroundImage, dataDirectoryBasePath]);

    return (
      <IonPage>
        <IonContent fullscreen forceOverscroll={false}>
          <Section
            backgroundImage={backgroundImageUrl}
            backgroundPosition={backgroundPosition || 'center'}
            className={cx('absolute inset-0', !isNative && 'mx-auto max-h-full', className)}
            ref={ref}
          >
            <div className={cx('absolute left-8 right-8 bottom-8 top-0', innerClassName)}>
              <div className={cx('service-menu-grid-container', mostInnerClassName)}>
                {headline && (
                  <h2
                    className="service-menu-grid-container__headline bosch-text-headline"
                    dangerouslySetInnerHTML={{
                      __html: replaceBrWithSpace(headline),
                    }}
                  />
                )}
                <div
                  className={cx(
                    'service-menu-grid-container__stage',
                    `service-menu-grid-container__stage_${numberOfItems <= 15 ? numberOfItems : 'more'}`
                  )}
                >
                  {children}
                </div>
              </div>
            </div>
          </Section>
        </IonContent>
      </IonPage>
    );
  }
);
