import { useTranslation } from "react-i18next";
import { BaseGridContainer } from '../base-grid-container';
import { IonButton, IonToggle } from "@ionic/react";
import { Preferences } from "@capacitor/preferences";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { useAlerts } from "../../hooks/useAlert";
import { useEffect, useState } from "react";
import { Device, DeviceInfo } from "@capacitor/device";
import axios from "axios";
import pkJson from "../../../package.json";

const MIN_DISPLAY_TIME = 1500;

export const SettingsDebugPage = () => {
  const { t } = useTranslation();
  const [logEntries, setLogEntries] = useState<string[]>(() =>
    JSON.parse((localStorage.getItem("logs") as string) || "[]").reverse()
  );
  const [isDebugMode, setIsDebugMode] = useState(
    JSON.parse(localStorage.getItem("debugMode") as string) || false
  );
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const {
    presentDeleteContentSuccessAlert,
    presentDeleteContentFailureAlert,
    presentConfirmDeletionAlert,
    presentSendingDiagnosticDataFailureAlert,
    presentSendingDiagnosticDataSuccessAlert,
    presentRestartAppToTakeEffectAlert,
  } = useAlerts();

  useEffect(() => {
    gatherDeviceInfos();
  }, []);

  useEffect(() => {
    setLogEntries(
      JSON.parse((localStorage.getItem("logs") as string) || "[]").reverse()
    );
  }, [isDebugMode]);

  const sendLogEntries = async () => {
    console.info("Uploading diagnostic logs...");
    const start = Date.now();

/*    dispatchNotificationAction(
      presentFullscreenLoadingIndicator({
        message: t("sendingLogData__hint"),
      })
    );*/

    const deviceId = await Device.getId();

    const payload = {
      deviceInfo: { ...deviceInfo, uuid: deviceId.identifier },
      logs: logEntries,
    };

    try {
      await axios.post("actions/device-info", payload);

      const end = Date.now();
      if (end - start < MIN_DISPLAY_TIME) {
        await new Promise((resolve) =>
          setTimeout(resolve, MIN_DISPLAY_TIME - (end - start))
        );
      }
      /*dispatchNotificationAction(dismissFullscreenLoadingIndicator());*/

      setTimeout(() => {
        presentSendingDiagnosticDataSuccessAlert();
      }, 1000);
    } catch (e) {
      console.warn(e);

      const end = Date.now();
      if (end - start < MIN_DISPLAY_TIME) {
        await new Promise((resolve) =>
          setTimeout(resolve, MIN_DISPLAY_TIME - (end - start))
        );
      }
      /*dispatchNotificationAction(dismissFullscreenLoadingIndicator());*/
      setTimeout(() => {
        presentSendingDiagnosticDataFailureAlert();
      }, 1000);
    } finally {
    }
  };

  const deleteAll = async () => {
    /*dispatchNotificationAction(
      presentFullscreenLoadingIndicator({
        message: t("deleteAlert.deleteContent__progress"),
      })
    );*/
    try {
      await Preferences.clear();

      const dirContents = await Filesystem.readdir({
        directory: Directory.Documents,
        path: "",
      });

      await Promise.all(
        dirContents.files
          .filter((file) => file.name.includes(".json"))
          .map((file) => {
            return Filesystem.deleteFile({
              directory: Directory.Documents,
              path: file.uri,
            });
          })
      );

      await Filesystem.rmdir({
        directory: Directory.Documents,
        path: "assets",
        recursive: true,
      });

      /*dispatchNotificationAction(dismissFullscreenLoadingIndicator());*/
      setTimeout(() => {
        presentDeleteContentSuccessAlert();
      }, 1000);
    } catch (e) {
      console.log(e);
      /*dispatchNotificationAction(dismissFullscreenLoadingIndicator());*/

      setTimeout(() => {
        presentDeleteContentFailureAlert();
      }, 1000);
    }
  };
  const deleteLogs = () => {
    setLogEntries([]);
    localStorage.setItem("logs", "[]");
  };

  const gatherDeviceInfos = async () => {
    const rawDeviceInfo = await Device.getInfo();
    setDeviceInfo(rawDeviceInfo);
  };

  return (
    <BaseGridContainer
      className="bg-bosch-light-gray-w75"
      headline={t("settings.debug.headline")}
      mostInnerClassName={"pt-4"}
    >
      <div className="row-span-8 col-span-12">
        <section className="relative flex flex-col justify-between rounded-lg bg-white p-8 text-left shadow-lg">
          <h2 className="text-2xl font-bold">
            {t("settings.dataManagement.dangerArea__headline")}
          </h2>
          <p className="mt-4">
            {t("settings.dataManagement.dangerArea__body")}
          </p>
          <div className="mt-4">
            <IonButton
              fill="solid"
              className="font-bold"
              color="danger"
              onClick={() =>
                presentConfirmDeletionAlert(
                  "deleteAlert.deleteContent",
                  deleteAll
                )
              }
            >
              {t("settings.dataManagement.dangerAreaDeleteAllContent__button")}
            </IonButton>
          </div>
        </section>
        <section className="relative mt-6 flex flex-col justify-between rounded-lg bg-white p-8 text-left shadow-lg">
          <header className="flex items-center justify-between">
            <h2 className="text-2xl font-bold">
              {t("settings.debug.logEntries__headline")}
            </h2>
            <div className="flex items-center justify-center">
              <span className="mr-2">
                {t("settings.debug.logEntries__toggle")}
              </span>
              <IonToggle
                color="primary"
                checked={isDebugMode}
                onClick={() => {
                  console.info(
                    `Debug mode was ${!isDebugMode ? "enabled" : "disabled"}`
                  );
                  setIsDebugMode(!isDebugMode);
                  localStorage.setItem(
                    "debugMode",
                    !isDebugMode ? "true" : "false"
                  );

                  presentRestartAppToTakeEffectAlert();
                }}
              />
            </div>
          </header>
          <div>
            App Version: {pkJson.version}
          </div>
          <div className="mt-6 h-32 w-full overflow-y-scroll bg-bosch-light-gray-w75 p-4 font-mono">
            <ul>
              {logEntries.map((logEntry: string) => (
                <li>{logEntry}</li>
              ))}
            </ul>
          </div>
          <div className="mt-4">
            <IonButton
              fill="solid"
              className="font-bold"
              color="primary"
              onClick={() => sendLogEntries()}
              disabled={logEntries.length === 0}
            >
              {t("settings.debug.logEntries_send__button")}
            </IonButton>
            <IonButton
              fill="solid"
              className="font-bold"
              color="danger"
              onClick={() =>
                presentConfirmDeletionAlert(
                  "deleteAlert.deleteLogs",
                  deleteLogs
                )
              }
              disabled={logEntries.length === 0}
            >
              {t("settings.debug.logEntries_delete__button")}
            </IonButton>
          </div>
        </section>
        {/*<div
                        className="relative mt-12 flex flex-col justify-between rounded-lg bg-white p-8 text-left shadow-lg">
                        <div className="mb-2 text-xl font-bold text-bosch-dark-blue divide-y">
                            {userDefaults?.language?.available?.map((language: string) => (
                                <div key={language}>
                                    <div className="text-xl font-bold text-bosch-dark-blue py-4">
                                        {t(`languages.${language}`)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>*/}
      </div>
    </BaseGridContainer>
  );
};
