import { useLocation } from 'react-router';
import { useEffect, useRef } from 'react';
import qs from 'qs';
import jsonata from 'jsonata';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';

export const usePageData = () => {
  const location = useLocation();
  const nodeRef = useRef<string | null>(null);

  const { coreData } = useCoreDataV2Store();
  const getPageData = async (expression?: string) => {
    if (coreData && expression) {
      return jsonata(expression || '$').evaluate(coreData);
    }
    
    console.log('get page data reject')
    return Promise.reject();
  }


  useEffect(() => {
    if (!nodeRef.current) {
      nodeRef.current = (qs.parse(location.search, { ignoreQueryPrefix: true }) as { node: string }).node;
    }
  }, [location.search]);

  return { nodeRef, getPageData };
};
