import { Component } from '../../stores/coreDataV2/coreData-v2-types';

export type FlagLabelProps = Partial<Component> & {
  hasLink: boolean;
  hasChildren: boolean;
  idx?: number;
  toggleFn: () => void;
  parentNode: string;
  flagId: string;
}

export const FlagLabel = ({
  hasLink = false,
  hasChildren = false,
  idx,
  toggleFn,
  parentNode,
  flagId,
  ...componentProps
}: FlagLabelProps) => {
  

  if (hasLink) {
    return (
      <span
        className="relative"
        dangerouslySetInnerHTML={{ __html: componentProps.label || 'UPDATE THIS' }}
        data-testid={flagId}
      />
    );
  }

  return <div dangerouslySetInnerHTML={{ __html: componentProps.label || 'UPDATE THIS' }} onClick={hasChildren ? toggleFn : undefined} />;
};
