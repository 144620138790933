import { animated, useTransition } from 'react-spring';
import { useEffect, useState } from 'react';
import { staggeredTransitionConfig } from '../../animations/animation';
import { round } from 'lodash';
import { cx } from '@emotion/css';
import { FlagLabel } from './flag-label';
import { Flag, Position, Service } from '../../stores/coreDataV2/coreData-v2-types';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { isDebugMode } from '../../utils/debug.utils';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';
import qs from 'qs';
import {produce} from "immer"
import { useQueryClient } from '@tanstack/react-query';

export type ExtendedPosition = Position & { overlapOffset?: number; width?: number; height?: number; isNotPositioned?: boolean; rawX: number; rawY: number; }
export type FlagProps = Exclude<Flag, 'position'> & {
  parentNode: string;
  styles: any;
  idx: number;
  position: ExtendedPosition;
};

export const FlagComponent = ({ parentNode, styles, component, ...props }: FlagProps) => {
  const [theChildrenData, setTheChildrenData] = useState<any[]>([]);
  const transitions = useTransition(theChildrenData, staggeredTransitionConfig);
  const hasLink = Boolean(component?.headline);
  const hasChildren = props.children && props.children?.length > 0 || false;
  const location = useLocation();
  const isClickable = hasLink || hasChildren;
  const [currentTopicId, setCurrentTopicId] = useState<string | null>(null);
  const toggleFn = () => {
    theChildrenData.length === 0 ? setTheChildrenData(props.children as any) : setTheChildrenData([]);
  };
  
  const getCanvasPosition = (coordinates: Pick<ExtendedPosition, 'x' | 'y'>): { left: string; top: string } => {
    return { left: `${round(coordinates.x)}px`, top: `${round(coordinates.y)}px` };
  };
  
  
  const getPolePosition = (
    position: ExtendedPosition,
    isClickable: boolean,
    ): { left?: string; right?: string; top: string; height: string; width: string; backgroundColor?: string; border?: string } => {
      
      if (position.direction.startsWith('DOWN')) {
        return {
          left: position.direction === 'DOWN-LEFT' ? '-1px' : '0px',
          top: `-${round(position.length + (position?.overlapOffset || 0))}px`,
          height: `${round(position.length + (position?.overlapOffset || 0))}px`,
          width: '1px',
          backgroundColor: isClickable ? 'rgb(0,86,145)' : 'rgb(82,95,107)',
        };
      } else if (position.direction.startsWith('UP')) {
        return {
          left: `${0}px`,
          top: `0px`,
          height: `${round(position.length + (position?.overlapOffset || 0))}px`,
          width: '1px',
          backgroundColor: isClickable ? 'rgb(0,86,145)' : 'rgb(82,95,107)',
        };
      } else if (position.direction.startsWith('RIGHT')) {
        return {
          right: `100%`,
          top: `0px`,
          height: '1px',
          width: `${round(position.length + (position?.overlapOffset || 0))}px`,
          backgroundColor: isClickable ? 'rgb(0,86,145)' : 'rgb(82,95,107)',
        };
      } else if (position.direction.startsWith('LEFT')) {
        return {
          left: `0px`,
          top: `0px`,
          height: '1px',
          width: `${round(position.length + (position?.overlapOffset || 0))}px`,
          backgroundColor: isClickable ? 'rgb(0,86,145)' : 'rgb(82,95,107)',
        };
      } else {
        return {
          left: `${0}px`,
          top: `${0}px`,
          height: `${0}px`,
          width: `${0}px`,
        };
      }
    };
    
    useEffect(() => {
      if (location.search) {
        const regex = /topics\[id="(.+)"\]/;
        const currentPath = (qs.parse(location.search, { ignoreQueryPrefix: true }) as { node: string }).node;
        const match = currentPath?.match(regex);
        setCurrentTopicId(match ? match[1] : null)
      }
    }, [location.search]);
    
    const {coreData, setCoreData} = useCoreDataV2Store();
    
    const onChangeRawX = async (e: any) => {
      const newX = parseInt(e.target.value, 10);
      
      const updatedCD = produce(coreData, (draft) => {
        const topic = draft.topics.find(topic => topic.id === currentTopicId);
        if (!topic) return;
        const flag = topic?.flags?.find(flag => flag.id === props.id);
        if (!flag) return;
        flag.position.x = newX;
        return draft;
      })

      setCoreData(updatedCD);
      console.log(props.tag, `${newX},${props.position.rawY},${props.position.direction},${props.position.length}`);
    }

    const onChangeRawY = async (e: any) => {
      const newY = parseInt(e.target.value, 10);

      const updatedCD = produce(coreData, (draft) => {
        const topic = draft.topics.find(topic => topic.id === currentTopicId);
        if (!topic) return;
        const flag = topic?.flags?.find(flag => flag.id === props.id);
        if (!flag) return;
        flag.position.y = newY;
        return draft;
      })

      setCoreData(updatedCD);
      console.log(props.tag, `${props.position.rawX},${newY},${props.position.direction},${props.position.length}`);
    }

    const onChangeLength = async (e: any) => {
      const newLength = parseInt(e.target.value, 10);
    
      const updatedCD = produce(coreData, (draft) => {
        const topic = draft.topics.find(topic => topic.id === currentTopicId);
        if (!topic) return;
        const flag = topic?.flags?.find(flag => flag.id === props.id);
        if (!flag) return;
        flag.position.length = newLength;
        return draft;
      })

      setCoreData(updatedCD);
      console.log(props.tag, `${props.position.rawX},${props.position.rawY},${props.position.direction},${newLength}`);
    }

    const onChangeDirection = async (e: any) => {
      const newDirection = e.target.value;
    
      const updatedCD = produce(coreData, (draft) => {
        const topic = draft.topics.find(topic => topic.id === currentTopicId);
        if (!topic) return;
        const flag = topic?.flags?.find(flag => flag.id === props.id);
        if (!flag) return;
        flag.position.direction = newDirection;
        return draft;
      })

      setCoreData(updatedCD);
      console.log(props.tag, `${props.position.rawX},${props.position.rawY},${newDirection},${props.position.length}`);
    }

    function findServicePath(services: Service[], serviceId: string, path: string = ''): string | null {
      for (const service of services) {
        if (service.id === serviceId) {
          return `${path}.services[id="${service.id}"]`;
        }
        if (service.services) {
          const deeperPath = findServicePath(service.services, serviceId, `${path}.services[id="${service.id}"]`);
          if (deeperPath) {
            return deeperPath;
          }
        }
      }
      return null;
    }
    
    return (
      <animated.div style={{ ...styles, ...getCanvasPosition(props.position) }} className="absolute">
      {hasLink && 
      <div className='relative'>
        <Link
        to={{
          pathname: routes.component__detail,
          search: `?node=${parentNode}.flags[id="${props.id}"].component[id="${component.id}"]`,
        }}
        className={cx(
          'absolute whitespace-nowrap py-2 px-3 font-bold text-white shadow-xl cursor-pointer bg-bosch-dark-blue',
          (props.position.direction === 'DOWN-LEFT' || props.position.direction === 'UP-LEFT') && 'right-0',
          props.position.direction === 'RIGHT' && 'right-0',
            props.position.isNotPositioned && 'border-2 border-red-600 z-10',
          )}
        >
          <FlagLabel
            hasLink={hasLink}
            hasChildren={hasChildren}
            toggleFn={toggleFn}
            parentNode={parentNode}
            flagId={props.id}
            {...component}
          />
      </Link>
          {isDebugMode() && <div className="absolute -top-7 text-white left-full flex space-x-2">
            <input type="text" defaultValue={props.position.rawX} onBlur={onChangeRawX} className='p-1 w-12'/>
            <input type="text" defaultValue={props.position.rawY} onBlur={onChangeRawY} className='p-1 w-12'/>
            <input type="text" defaultValue={props.position.direction} onBlur={onChangeDirection} className='p-1 w-16'/>
            <input type="text" defaultValue={props.position.length} onBlur={onChangeLength} className='p-1 w-12'/>
          </div>}
      </div>}
      {(!hasLink) && <span
          data-testid={props.id}
          className={cx(
            'absolute whitespace-nowrap py-2 px-3 font-bold text-white shadow-xl',
            hasChildren && 'cursor-pointer',
            hasChildren ? 'bg-bosch-dark-blue' : 'bg-bosch-dark-gray',
            (props.position.direction === 'DOWN-LEFT' || props.position.direction === 'UP-LEFT') && 'right-0',
            props.position.direction === 'RIGHT' && 'right-0',
            props.position.isNotPositioned && 'border-2 border-red-600 z-10',
          )}
        >
          <FlagLabel
            hasLink={false}
            hasChildren={hasChildren}
            toggleFn={toggleFn}
            parentNode={parentNode}
            flagId={props.id}
            {...component}
          />
          {isDebugMode() && <div className="absolute -top-7 text-white left-full flex space-x-2">
            <input type="text" defaultValue={props.position.rawX} onBlur={onChangeRawX} className='p-1 w-12'/>
            <input type="text" defaultValue={props.position.rawY} onBlur={onChangeRawY} className='p-1 w-12'/>
            <input type="text" defaultValue={props.position.direction} onBlur={onChangeDirection} className='p-1 w-12'/>
            <input type="text" defaultValue={props.position.length} onBlur={onChangeLength} className='p-1 w-12'/>
          </div>}
      </span>}
      <div className="absolute top-11 ml-3 inline-flex flex-col items-start">
        {transitions((styles, child) => {
          return (
            <animated.div
              style={styles}
              className={cx('relative mt-1 whitespace-nowrap py-2 px-3 font-bold text-white', child.parentServiceId ? 'bg-bosch-dark-blue' : 'bg-bosch-dark-gray')}
            >
              {child.parentServiceId && <Link
                to={{
                  pathname: routes.details__flags_and_modal,
                  search: `?node=${parentNode}${findServicePath(coreData?.topics.find(t => t.id === currentTopicId)?.services || [] || [], child.parentServiceId || '')}`,
                }}>
                <FlagLabel
                  hasLink={true}
                  hasChildren={false}
                  toggleFn={toggleFn}
                  parentNode={parentNode}
                  flagId={child.id}
                  {...child.component}
                />
              </Link>}
              {!child.parentServiceId && 
                <FlagLabel
                  hasLink={false}
                  hasChildren={false}
                  toggleFn={toggleFn}
                  parentNode={parentNode}
                  flagId={child.id}
                  {...child.component}
                />}
            </animated.div>
          );
        })}
      </div>
      <div style={{ ...getPolePosition(props.position, isClickable) }} className="absolute" />
    </animated.div>
  );
};
