import { IonContent, IonPage } from '@ionic/react';
import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import { forwardRef, ReactNode } from 'react';
import { isNative } from "../utils/platform";

const Section = styled.section((props: any) => ({
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: props.backgroundPosition,
  backgroundColor: '#fff',
}));

export type ComponentDetailContainerProps = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  layout?: 'LEFT' | 'RIGHT';
};

export const ComponentDetailContainer = forwardRef(
  (
    {
      children,
      backgroundImage,
      backgroundPosition,
      className,
      innerClassName,
      layout = 'RIGHT',
      ...props
    }: ComponentDetailContainerProps,
    ref
  ) => {
    return (
      <IonPage>
        <IonContent fullscreen forceOverscroll={false}>
          <Section
            backgroundImage={backgroundImage}
            backgroundPosition={backgroundPosition || 'center'}
            className={cx('absolute inset-0', !isNative && 'mx-auto max-h-full', className)}
            ref={ref}
          >
            <div className={cx('component-detail-container', `component-detail-container__layout-${layout}`, innerClassName)}>
              {children}
            </div>
          </Section>
        </IonContent>
      </IonPage>
    );
  }
);
