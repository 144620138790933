import {NavigationOptions} from 'swiper/types/modules/navigation';
import {pagination} from '../../components/swiper-pagination';
import {cx} from '@emotion/css';
import {IoCloseOutline} from '@react-icons/all-files/io5/IoCloseOutline';
import {RiFullscreenExitLine} from '@react-icons/all-files/ri/RiFullscreenExitLine';
import {RiFullscreenLine} from '@react-icons/all-files/ri/RiFullscreenLine';
import {ReactComponent as NextIcon, ReactComponent as PrevIcon} from '../../assets/icons/back-left.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useTranslation} from 'react-i18next';
import {useRef} from 'react';
import {useUserDefaultsStore} from '../../stores/userDefaults/useUserDefaults.store';
import {isNative} from '../../utils/platform';
import {VideoPlayer} from './video-player.component';

export interface DetailSliderProps {
    modalContent: any;
    onClose: () => void;
    onToggleFullscreen: () => void;
    isFullscreen: boolean;
    setSwiperInstance?: (swiper: any) => void;
    swiperInstance?: any;
}

export const DetailSlider = ({
                                 modalContent,
                                 onClose,
                                 onToggleFullscreen,
                                 isFullscreen,
                                 setSwiperInstance
                             }: DetailSliderProps) => {
    const {t} = useTranslation();
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const dataDirectoryBasePath = useUserDefaultsStore((state) => state.dataDirectoryBasePath);

    return (
        <Swiper
            slidesPerView={1}
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper) => {
                setTimeout(() => {
                    if (swiper && setSwiperInstance) {
                        setSwiperInstance(swiper);
                    }

                    if (!swiper.destroyed) {
                        (swiper.params?.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
                        (swiper.params?.navigation as NavigationOptions).nextEl = navigationNextRef.current;

                        // Re-init navigation
                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }
                });
            }}
            pagination={pagination}
            className={cx({hasPagination: modalContent?.length > 1, 'w-full': isFullscreen})}
        >
            {isNative && (
                <button
                    type="button"
                    className={cx(
                        'swiper__button swiper__button-fullscreen-toggle focus:outline-none',
                        modalContent?.some((slide: any) => slide.extension === 'mp4' || slide.extension === 'm3u8') && 'hidden'
                    )}
                    onClick={onToggleFullscreen}
                >
                    {isFullscreen && <RiFullscreenExitLine size="24px"/>}
                    {!isFullscreen && <RiFullscreenLine size="24px"/>}
                </button>
            )}

            <button type="button" className="swiper__button swiper__button-close" onClick={onClose}>
                <IoCloseOutline size="32px"/>
            </button>
            <div ref={navigationPrevRef}
                 className="absolute bottom-3 left-4 z-20 flex cursor-pointer items-center disabled:hidden">
                <PrevIcon className="h-8 w-8 fill-bosch-dark-blue"/>
                <span className="ml-1 mt-0.5 text-bosch-dark-blue">{t('slider__backButton')}</span>
            </div>
            <div ref={navigationNextRef}
                 className="absolute bottom-3 right-4 z-20 flex cursor-pointer items-center disabled:hidden">
                <span className="ml-1 mt-0.5 text-bosch-dark-blue">{t('slider__nextButton')}</span>
                <NextIcon className="h-8 w-8 rotate-180 transform fill-bosch-dark-blue"/>
            </div>
            {modalContent?.map((item: any) => {
                return (
                    <SwiperSlide key={item.path} className="relative min-h-[300px]">
                        {item.extension === 'png' &&
                            <img src={isNative ? `${dataDirectoryBasePath}${item.path}` : item.url} alt="Image"
                                 className={cx(isFullscreen ? ' w-full h-full max-w-none' : 'h-auto w-auto')}/>}
                        {item.extension === 'm3u8' && <VideoPlayer videoItem={item}/>}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};
