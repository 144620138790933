export interface CoreDataV2State {
    coreData: CoreDataV2;
    setCoreData: (coreData: CoreDataV2) => void;
  }

  
export interface CoreDataV2 {
    topics: Topic[]
    meta: Meta,
    environment: string;
    id: string;
    language: string;
    status: Status;
    tag: string;
    modifiedAt: string;
    title: string;
    assets: any[];
    url: string;
    isLatest: boolean,
}

export interface Meta {
    assets: AssetMeta;
}

export interface AssetMeta {
    sizeKbs: number;
    count: number;
    items: AssetMetaItem[];   
}

export interface AssetMetaItem {
    sizeKbs: number;
    url: string;
}

export interface Flag {
    environment: string;
    id:          string;
    language:    string;
    status:      Status;
    tag:         string;
    modifiedAt:  string;
    position:    Position;
    component:   Component;
    groupedByFlagId?: string;
    children?: Flag[];
}

export interface Asset {
    environment: string;
    id:          string;
    language:    string;
    tag:         string;
    modifiedAt:  string;
    path:        string;
    fileName:    string;
    extension:   string;
    bundleId:    string;
    sizeBytes:   number;
    url:         string;

    // TODO: remove this
    poster?: string;
}
export interface Topic {
    environment: string;
    id:          string;
    language:    string;
    status:      Status;
    tag:         string;
    modifiedAt:  string;
    order:       number;
    label:       string;
    headline:    string;
    description: string;
    services?:   Service[];
    flags?:      Flag[];
    flagIds?:    string[];
    oldTag?:     string;
    buttons?:    any[];
    background?: Asset;
}

export interface Service {
    environment: string;
    id:          string;
    language:    string;
    status:      Status;
    tag:         string;
    modifiedAt:  string;
    order:       number;
    label:       string;
    headline:    string;
    description: string;
    services?:   Service[];
    flags?:      Flag[];
    flagIds?:    string[];
    oldTag?:     string;
    buttons?:    any[];
    asset?:     any;
    backgrounds?: Asset[];
}

export type Component = Service;

export interface Position {
    x:         number;
    y:         number;
    direction: Direction;
    length:    number;
}

export enum Direction {
    Down = "DOWN",
    DownLeft = "DOWN-LEFT",
    Left = "LEFT",
    Right = "RIGHT",
    Up = "UP",
    UpLeft = "UP-LEFT",
}

export enum Status {
    Draft = "DRAFT",
    Published = "PUBLISHED",
    Archived = "ARCHIVED",
}