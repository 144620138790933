import create from 'zustand';
import {CoreDataV2, CoreDataV2State, Status} from './coreData-v2-types';
import {persist} from 'zustand/middleware';
import produce from 'immer';
import {isNative} from '../../utils/platform';

export const useCoreDataV2Store = create<CoreDataV2State>()(
    persist(
        (set) => ({
            coreData: {
                environment: "",
                id: "",
                language: "en",
                status: Status.Draft,
                tag: "0000",
                modifiedAt: "2023-08-10 12:14:20",
                title: "Let’s shape a new era of mobility. Together.",
                assets: [],
                meta: {assets: {items: [], count: 0, sizeKbs: 0}},
                url: "",
                isLatest: true,
                topics: []
            },

            setCoreData: (coreData: CoreDataV2) => {
                set(
                    produce((draft) => {
                        draft.coreData = coreData;
                        draft.coreData.language = coreData.language.includes('-uaes') ? coreData.language.replace('-uaes', '') : coreData.language;
                    })
                );
            },

        }),
        {
            name: 'SPACE_CAR_V2_CORE_DATA',
            getStorage: () => (isNative ? localStorage : sessionStorage),
        }
    )
);
