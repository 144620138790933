import { ReactNode } from 'react'

const replaceBrWithSpace = (input: string | object): string => {
    if (typeof input === 'object') {
        return ''
    }
    return !!input ? input.replace(/<br\/?>/g, ' ') : input;

}
const removeTrailingSlash = (input: string): string => input.slice(0, -1)

const transformPlainTextToHtmlList = (input: string): ReactNode | null => {
  if (!input || input.length === 0) {
        return null
    }


    const splitContent = input.split(/<br\/?>– /);

    const copyText = splitContent[0].startsWith('– ') ? null : splitContent[0];

    const list = splitContent
        .slice(1, splitContent.length)
        .map((item, index) => {
            return (
                <li
                    key={index}
                    className="mt-2"
                    dangerouslySetInnerHTML={{__html: item}}
                />
            )
        })

    return (
      <>
        {copyText && <p dangerouslySetInnerHTML={{ __html: copyText }} />}
        <ul className="mt-4 list-square pl-5">{list}</ul>
      </>
    );
}
export { replaceBrWithSpace, removeTrailingSlash, transformPlainTextToHtmlList }

