import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HomeGridContainer } from '../../components/home-grid-container';
import '../../styles/grids/home-grid-container.scss';
import { routes } from '../../utils/routes';
import { Topic } from '../../stores/coreDataV2/coreData-v2-types';
import { useQuery } from '@tanstack/react-query';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { usePageData } from '../../hooks/usePageData/usePageData.hook';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';
import { motion } from 'framer-motion';
import { defaults } from '../../static-data/defaults';
import { useEffect, useState } from 'react';
import { sortTopics } from '../../utils/home-sort';
import { cx } from '@emotion/css';
import {isUAESubdomain} from "../../constants/defaults";

const TOPICS_COLORS = new Map<string, string>([
  ['0004', 'bosch-adas'],
  ['0001', 'bosch-motion'],
  ['0002', 'bosch-energy'],
  ['0003', 'bosch-services'],
  ['0005', 'bosch-compute'],
  ['0007', 'bosch-semiconductor'],
]);

const TOPICS_BGS = new Map<string, string>([
  ['0004', 'circle'],
  ['0001', 'waves'],
  ['0002', 'lines'],
  ['0003', 'triangles'],
  ['0005', 'squares'],
  ['0007', 'squares'],
]);

const AnimatedLink = motion(Link)

export const Home = () => {
  const { t } = useTranslation();
  const { coreData } = useCoreDataV2Store();
  const { getPageData } = usePageData();
  const { language } = useUserDefaultsStore();
  
  const topicQuery = useQuery(['SPACECAR', coreData?.id, language.current, 'topics'], () => getPageData('topics'), {enabled: !!coreData?.id})
  
  const [animationState, setAnimationState] = useState('hidden');
  
  const variants = {
    visible: {
      transition: { staggerChildren: 0.07, delayChildren: 0.3 }
    },
    hidden: {
      transition: { staggerChildren: 0.1, staggerDirection: -1 }
    }
  };

  const childVariants = {
    visible: {
      y: 0,
      opacity: 1,
    },
    hidden: {
      y: -50,
      opacity: 0,
    }
  };

  useEffect(() => {
    if (topicQuery.data) {
     setTimeout(() => {
      setAnimationState('visible')
     }, 100)
    }
  }, [topicQuery.data])

  return (
    <HomeGridContainer backgroundImage={isUAESubdomain ? defaults.backgroundImageUAES : defaults.backgroundImage}>
      <div className='flex flex-col h-full w-full flex-1 items-center justify-end'>
        <motion.div 
            className='grid gap-5 grid-rows-1 grid-cols-6 relative mx-auto'
            initial={"hidden"}
            animate={animationState}
            variants={variants}>
          {sortTopics(topicQuery.data)?.map((topic: Topic) => (
              <AnimatedLink
                to={{
                  pathname: routes.overview,
                  search: '?node=' + `topics[id="${topic.id}"]`,
                }}
                key={topic.id}
                className={cx(
                  TOPICS_COLORS.has(topic.tag)
                    ? `bg-${TOPICS_COLORS.get(topic.tag)}`
                    : 'bg-bosch-light-blue',
                    TOPICS_BGS.has(topic.tag)
                    && `bg-${TOPICS_BGS.get(topic.tag)}`,
                    'bg-[-2rem_-2rem] bg-no-repeat bg-[length:80%]',
                    'w-full min-w-[200px] max-w-[230px] flex self-end basis-0 flex-grow aspect-square items-end p-6 text-xl font-bold text-white backdrop-blur-sm overflow-hidden'
                  )}
                variants={childVariants}
              >
                <span className='block break-words hyphens'>{topic.label}</span>
              </AnimatedLink>))}
        </motion.div>
        {topicQuery.data && (
          <motion.div
          className="flex text-center text-xl text-bosch-light-blue justify-center items-center h-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.0 }}>
            {t('chooseTopic__hint')}
          </motion.div>
        )}
      </div>
    </HomeGridContainer>
  );
};

export default Home;
