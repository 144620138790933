import { PaginationOptions } from 'swiper/types'

export const pagination: PaginationOptions = {
    clickable: true,
    type: 'custom',
    renderCustom: function (swiper: any, current: number, total: number) {
        return `<div class="inline-block mx-auto left-0 right-0 bottom-0 z-0 relative">
       <div class="bg-bosch-light-gray-w25" style="position: absolute; height: 1px; bottom: 10px; width: 100%; z-index: 0;"></div>
       <div class="bg-bosch-dark-blue" style="position: absolute; height: 1px; bottom: 10px; z-index: 1; width: ${
            total === current ? 100 : (100 / (total - 1)) * (current - 1)
        }%"></div>
        <div style="position: relative; z-index: 2;">
        ${[...Array(total)]
            .map(
                (item: any, index: number) =>
                    `<div class="bullet ${
                        index <= current - 1
                            ? 'bg-bosch-dark-blue'
                            : 'bg-bosch-light-gray-w25'
                    }"></div>`
            )
            .join('')}
        </div>
      </div>`
    },
}
