import styled from '@emotion/styled';

export const NavbarHeaderComponent = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  padding-top: 10px;
  border-bottom: 1px solid #c1c7cc;
  background-color: #fff;
  height: 60px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-image: url(./assets/images/supergraphic.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
`;
