export const routes = {
    root: '/',

    loading: '/loading',
    home: '/home',

    settings: '/settings',
    settings__data: '/settings/data',
    settings__debug: '/settings/debug',

    overview: '/overview',

    services__overview: '/services-overview',
    services__overview_nested: '/services-overview/nested-services-overview',
    services__overview_nested_level_three: '/services-overview/nested-services-overview/nested-services-overview-level-three',

    components__overview: '/components-overview',
    component__detail: '/component-detail',

    details__flags_and_modal: '/details-with-flags-and-modal',
}
