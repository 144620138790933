import { IonButton, useIonPopover, useIonRouter } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { useRef } from 'react';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/back-left.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as ChangeLanguageIcon } from '../../assets/icons/globe.svg';
import { useUserDefaultsStore } from '../../stores/userDefaults/useUserDefaults.store';
import { NavbarHeaderComponent } from './NavbarHeader.component';
import { APP_PLATFORM, isNative } from '../../utils/platform';
import { useCoreDataV2Store } from '../../stores/coreDataV2/useCoreDataV2Store.store';
import { translations } from '../../static-data/translations';
import { NavbarLanguageSelectorPopoverComponent } from './NavbarLanguageSelectorPopover.component';

export const NavbarComponent = () => {
  const history = useHistory();
  const router = useIonRouter();
  const location = useLocation();

  const logoClickedCount = useRef(0);
  const userDefaultsStore = useUserDefaultsStore();
  const { coreData } = useCoreDataV2Store();

  const logoClick = () => {
    // go back to root in an ionic app
    if (!isNative) {
      // TODO: Replace this with a more performant solution
      window.location.href = '/';
    }

    if (logoClickedCount.current === 5) {
      logoClickedCount.current = 0;
      userDefaultsStore.setShowSettingsDialog(true);
    } else {
      logoClickedCount.current++;
    }
  };

  const goBack = () => {
    const canGoBack = router.canGoBack();
    if (canGoBack) {
      router.goBack();
      setTimeout(() => {
        goBack();
      }, 0);
    }
  };

  const availableLanguages = 
    APP_PLATFORM === 'UAES'
      ? userDefaultsStore?.language.available.filter((lang) => lang !== 'de')
      : userDefaultsStore?.language.available;

  const translatedLanguageNames = (translations as any)[userDefaultsStore?.language?.current]?.translation?.languages;
  
  const [present, dismiss] = useIonPopover(NavbarLanguageSelectorPopoverComponent, {
    availableLanguages:
      APP_PLATFORM === 'UAES'
        ? userDefaultsStore?.language.available.filter((lang) => lang !== 'de')
        : userDefaultsStore?.language.available,
    reference: 'event',
    currentLanguage: userDefaultsStore?.language?.current,
    changeLanguage: (language: string) => {
      userDefaultsStore.setCurrentLanguage(language);
      dismiss();
    },
  });

  // <h1 className="font-bold text-black">{coreData?.title || 'Let’s shape a new era of mobility. Together.'}</h1>
  return (
    <NavbarHeaderComponent className="flex items-center justify-between px-8">
      <h1 className="font-bold text-black">{coreData?.title || 'Let’s shape a new era of mobility. Together.'}</h1>
      <div className="flex items-center justify-center">
        <nav className="mr-8 flex items-center justify-between relative">
          <IonButton fill="clear" onClick={() => history.goBack()} disabled={location.pathname === '/home'}>
            <BackIcon className="h-6 w-6" />
          </IonButton>
          <IonButton onClick={isNative ? goBack : logoClick} fill="clear" disabled={location.pathname === '/home'}>
            <HomeIcon className="h-6 w-6" />
          </IonButton>
          <IonButton fill="clear" onClick={(event) => present({ event: event.nativeEvent })}>
            <ChangeLanguageIcon className="h-6 w-6" />
          </IonButton>
        </nav>
        <Logo className="h-[25px] cursor-pointer" onClick={logoClick} />
      </div>
    </NavbarHeaderComponent>
  );
};
