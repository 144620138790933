import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { translations } from './static-data/translations'

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false
        },
        resources: translations
    })


export default i18n
