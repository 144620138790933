import {useRef, useState} from 'react';
import {FlagComponent} from '../../components/flag/flag';
import {useTransition} from 'react-spring';
import {SubpageGridContainer} from '../../components/subpage-grid-container';
import {getSubgridContentData} from '../../utils/data-extractor';
import qs from 'qs';
import {IonButton, useIonViewWillLeave} from '@ionic/react';
import {cx} from '@emotion/css';
import {DetailSlider} from './detail-slider';
import {usePageData} from '../../hooks/usePageData/usePageData.hook';
import {useFlags} from '../../hooks/useFlags/useFlags.hook';
import * as Dialog from '@radix-ui/react-dialog';
import './details-with-flags-and-modal.scss';
import {isNative} from '../../utils/platform';
import {useQuery} from '@tanstack/react-query';
import {useUserDefaultsStore} from '../../stores/userDefaults/useUserDefaults.store';
import {Asset} from '../../stores/coreDataV2/coreData-v2-types';
import {useCoreDataV2Store} from '../../stores/coreDataV2/useCoreDataV2Store.store';
import {staggeredTransitionConfig} from '../../animations/animation';

export const DetailsWithFlagsAndModal = () => {
    const imageContainerRef = useRef<any | null>(null);
    const {getPageData, nodeRef} = usePageData();
    const {language} = useUserDefaultsStore();
    const {coreData} = useCoreDataV2Store();
    const serviceQuery = useQuery(['SPACECAR', coreData?.id, language.current, ...(nodeRef.current?.split('.') || [])], () => getPageData(nodeRef.current as string), {enabled: !!nodeRef.current})
    const {flags} = useFlags(serviceQuery.data?.flags, imageContainerRef);
    const transitions = useTransition(flags, staggeredTransitionConfig);

    const [modalContent, setModalContent] = useState<any>(null);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const modalDetailRef = useRef<any>();

    const [swiperInstance, setSwiperInstance] = useState<any>(null);

    useIonViewWillLeave(() => {
        setIsFullscreen(false);
        setModalContent(null);
    });

    const onClose = () => {
        setIsFullscreen(false);
        setModalContent(null);
    };

    const onToggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const dialogStyles = !isFullscreen
        ? {
            width: modalDetailRef.current?.getBoundingClientRect()?.width,
            height: modalDetailRef.current?.getBoundingClientRect()?.height,
            top: modalDetailRef.current?.getBoundingClientRect()?.top,
            left: modalDetailRef.current?.getBoundingClientRect()?.left,
        }
        : {};


    return (
        <SubpageGridContainer {...getSubgridContentData(serviceQuery.data, true)} ref={imageContainerRef}>
            <Dialog.Root open={modalContent}>
                <Dialog.Trigger/>
                <Dialog.Portal>
                    <Dialog.Overlay data-dialog-overlay onClick={(e) => {
                        if ((e.target as HTMLElement)?.hasAttribute('data-dialog-overlay')) {
                            setModalContent(null);
                        }
                    }}>
                        <Dialog.Content
                            className={cx(isFullscreen ? 'detail-modal__fullscreen' : isNative ? 'detail-modal__normal' : 'detail-modal__web')}
                            style={isNative ? dialogStyles : {}}
                            data-dialog-content>
                            <DetailSlider
                                onToggleFullscreen={onToggleFullscreen}
                                onClose={onClose}
                                modalContent={modalContent}
                                isFullscreen={isFullscreen}
                                setSwiperInstance={setSwiperInstance}
                                swiperInstance={swiperInstance}
                            />
                        </Dialog.Content>
                    </Dialog.Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            {isNative && (
                <div className={'subpage-grid-container__detail'}>
                    <div ref={modalDetailRef} className="h-full w-full"/>
                </div>
            )}
            <div className="absolute -ml-8">
                {transitions((styles, flag) => {
                    return (
                        <FlagComponent
                            key={flag?.id}
                            styles={styles}
                            idx={flag?.idx}
                            parentNode={qs.parse(location.search, {ignoreQueryPrefix: true}).node}
                            component={flag?.component}
                            {...flag}
                        />
                    );
                })}
            </div>
            {serviceQuery.data?.buttons.map((button: { label: string; assets: Asset[] }) => {
                return {
                    ...button,
                    assets: button.assets?.filter((asset) => !asset.fileName.includes('_poster'))
                };
            })?.map(({label, assets}: any, idx: number) => {
                return (
                    <IonButton
                        key={`button-${label.replace(' ', '')}-${idx}`}
                        fill="solid"
                        color={JSON.stringify(modalContent) === JSON.stringify(assets) ? 'primary' : 'secondary'}
                        className={`subpage-grid-container__button-${idx} m-0 h-auto self-end font-bold`}
                        onClick={() => {
                            swiperInstance?.slideTo(0, 0);
                            if (assets) {
                                setModalContent(JSON.stringify(modalContent) === JSON.stringify(assets) ? null : assets);
                            }
                        }}>
            <span
                className="flex min-h-[50px] w-full items-center justify-center whitespace-normal py-2 text-sm leading-tight">
              <span dangerouslySetInnerHTML={{__html: label}}/>
            </span>
                    </IonButton>
                );
            })}
        </SubpageGridContainer>
    );
};
