import ReactPlayer from "react-player";
import { Asset } from '../../stores/coreDataV2/coreData-v2-types';
import { useUserDefaultsStore } from "../../stores/userDefaults/useUserDefaults.store";
import { isNative } from "../../utils/platform";

export type VideoPlayerProps = {
  videoItem: Required<Asset>;
};
export const VideoPlayer = ({ videoItem }: VideoPlayerProps) => {
  const { dataDirectoryBasePath } = useUserDefaultsStore();  

  return (
    <div className="background-loading min-h-[300px] transition-all">
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <ReactPlayer
            width="100%"
            height="100%"
            url={isNative ? videoItem.url?.replace(`${process.env.REACT_APP_BASE_URL_DATA}/`, dataDirectoryBasePath as string) : videoItem.url}
            controls={true}
            className={'absolute top-0 left-0 h-full w-full'}
          />
        </div>
    </div>
  );
};
