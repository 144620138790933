import create from 'zustand';
import { CurrentState, DownloadStatsState } from './useDownloadStats.types';
import produce from 'immer';

export const useDownloadStatsStore = create<DownloadStatsState>()(
  (set) => ({
    numberOfAssetsTotal: 0,
    numberOfAssetsDownloaded: 0,
    failedDownloads: 0,
    skippedDownloads: 0,

    totalBytes: 0,
    bytesRemaining: 0,

    currentFileBeingDownloaded: null,
    currentState: 'IDLE',

    increaseNumberOfAssetsDownloaded: () => {
      set(produce(draft => {
        draft.numberOfAssetsDownloaded += 1;
      }));
    },

    increaseFailedDownloads: () => {
      set(produce(draft => {
        draft.failedDownloads += 1;
      }));
    },

    updateNumberOfAssetsTotal: (numberOfAssetsTotal: number) => set(() => ({ numberOfAssetsTotal })),
    updateNumberOfAssetsDownloaded: (numberOfAssetsDownloaded: number) => set(() => ({ numberOfAssetsDownloaded })),
    updatedFailedDownloads: (failedDownloads: number) => set(() => ({ failedDownloads })),
    updateSkippedDownloads: (skippedDownloads: number) => set(() => ({ skippedDownloads })),
    updateTotalBytes: (totalBytes: number) => set(() => ({ totalBytes })),
    updateBytesRemaining: (bytesRemaining: number) => set(() => ({ bytesRemaining })),
    updateCurrentFileBeingDownloaded: (currentFileBeingDownloaded: string | null) => set(() => ({ currentFileBeingDownloaded })),
    updateCurrentState: (currentState: CurrentState) => set(() => ({ currentState }))
  })
);
