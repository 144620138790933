import { FC } from 'react';
import { translations } from '../../static-data/translations';
import { IonItem, IonList } from '@ionic/react';
import { checkmarkSharp } from 'ionicons/icons';

export const NavbarLanguageSelectorPopoverComponent: FC<{
  onHide: () => void;
  currentLanguage: string;
  availableLanguages: string[];
  changeLanguage: (language: string) => void;
}> = ({ availableLanguages, changeLanguage, currentLanguage }) => {
  const translatedLanguageNames = (translations as any)[currentLanguage]?.translation?.languages;

  return (
    <IonList>
      {availableLanguages.map((language, idx) => {
        return (
          <IonItem
            key={language}
            lines={idx === availableLanguages.length - 1 ? 'none' : 'full'}
            button
            detailIcon={currentLanguage === language ? checkmarkSharp : undefined}
            onClick={() => changeLanguage(language)}
          >
            {translatedLanguageNames[language]}
          </IonItem>
        );
      })}
    </IonList>
  );
};
