import { isPlatform } from '@ionic/react';

export const isNative = isPlatform('capacitor');

export type PLATFORM = 'NATIVE' | 'WEB' | 'UAES';
export const APP_PLATFORM: PLATFORM = isPlatform('capacitor')
  ? 'NATIVE'
  : window?.location?.hostname?.includes('cn') || window?.location?.hostname === 'localhost'
  ? 'UAES'
  : 'WEB';

export const getLocalFilePath = (fileUrl: string): string => {
  return fileUrl?.replace(`${process.env.REACT_APP_BASE_URL_DATA}/`, '');
}
